const LOGIN_USER = 'LOGIN_USER'
const REMOVE_USER = 'REMOVE_USER'
const START_MATCH = 'START_MATCH'
const SET_FAULT = 'SET_FAULT'
const STOP_MATCH = 'STOP_MATCH'
const ALL_MATCHES = 'ALL_MATCHES'
const ALL_USERS = 'ALL_USERS'
const ALL_PLAYERS = 'ALL_PLAYERS'
const ALL_TOURNAMENTS = 'ALL_TOURNAMENTS'

export {
    LOGIN_USER,
    REMOVE_USER,
    START_MATCH,
    STOP_MATCH,
    SET_FAULT,
    ALL_MATCHES,
    ALL_USERS,
    ALL_PLAYERS,
    ALL_TOURNAMENTS
}
