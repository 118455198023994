const HOME = '/'
const LOGIN = '/login'
const SIGNUP = '/register'
const SETTINGS = '/settings'
const HELP = '/help'
const TRANSFER = '/transfer'
const FORGOT = '/forgot'
const UPDATE_PASSWORD = '/update-password'
const VERIFY_EMAIL = '/verifyemail'
const ADMIN = '/admin'
const MATCH_TOOL = '/match-tool'
const USERS = `${ADMIN}/users`
const PLAYERS = `${ADMIN}/players`
const ADD_PLAYER = `${ADMIN}/add-player`
const TOURNAMENTS = `${ADMIN}/tournaments`
const ADD_TOURNAMENT = `${ADMIN}/add-tournament`

export default {
    HOME,
    LOGIN,
    SIGNUP,
    SETTINGS,
    HELP,
    TRANSFER,
    FORGOT,
    UPDATE_PASSWORD,
    VERIFY_EMAIL,
    ADMIN,
    PLAYERS,
    ADD_PLAYER,
    TOURNAMENTS,
    ADD_TOURNAMENT,
    USERS,
    MATCH_TOOL
}