import { LOGIN_USER, REMOVE_USER, ALL_MATCHES, ALL_USERS, ALL_PLAYERS, ALL_TOURNAMENTS } from '../types'

const loginUser = (user) => {
    return {
        type: LOGIN_USER,
        user
    }
}

const removeUser = () => {
    return {
        type: REMOVE_USER
    }
}

const addMatchesList = (allMatches) => {
    return {
        type: ALL_MATCHES,
        allMatches
    }
}

const addUsersList = (allUsers) => {
    return {
        type: ALL_USERS,
        allUsers
    }
}

const addPlayersList = (allPlayers) => {
    return {
        type: ALL_PLAYERS,
        allPlayers
    }
}

const addTournamentsList = (allTournaments) => {
    return {
        type: ALL_TOURNAMENTS,
        allTournaments
    }
}


export {
    loginUser,
    removeUser,
    addMatchesList,
    addUsersList,
    addPlayersList,
    addTournamentsList
}