import { START_MATCH, STOP_MATCH, SET_FAULT } from '../types'

const startMatch = (match) => {
    return {
        type: START_MATCH,
        match
    }
}

const setFault = (fault) => {
    return {
        type: SET_FAULT,
        fault
    }
}

const stopMatch = () => {
    return {
        type: STOP_MATCH
    }
}


export {
    startMatch,
    stopMatch,
    setFault
}