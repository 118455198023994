import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { List, Avatar, Spin, Popconfirm } from 'antd'
import { DeleteOutlined } from '@ant-design/icons'
import { convertTitle, successNotification, getAllData } from '../../utils/helpers'
import { AUTH } from '../../utils/apis'
import axios from 'axios'


const Users = (props) => {
    const { user, authActions } = props
    const [spin, setSpin] = useState({})
    const allUsers = useSelector(state => state?.authReducer?.allUsers) || []

    useEffect(() => {
        getAllData(user?._id, authActions)
    }, [])

    const changeRole = (userId, isAdmin) => {
        let obj = { userId, isAdmin }
        setSpin({ [userId]: true })
        axios.post(`${AUTH.CHANGE_ROLE}/${user?._id}`, obj)
            .then((res) => {
                const { data } = res
                setSpin({})
                if (data?.success) {
                    authActions?.addUsersList(data?.data || [])
                }
            })
            .catch((e) => {
                setSpin({})
            })
    }

    const deleteUser = (userId) => {
        setSpin({ [userId]: true })
        axios.post(`${AUTH.DELETE_ACCOUNT}/${user?._id}`, { userId })
            .then((res) => {
                const { data } = res
                setSpin({})
                if (data?.success) {
                    successNotification('Successfully Deleted!')
                    authActions?.addUsersList(data?.data || [])
                }
            })
            .catch((e) => {
                setSpin({})
            })
    }

    return (
        <div className='home-main'>
            <div className='card-box'>
                <div className='card3'>
                    <List
                        className='demo-loadmore-list'
                        itemLayout='horizontal'
                        pagination={{
                            pageSize: 10
                        }}
                        dataSource={allUsers}
                        renderItem={item => (
                            <List.Item
                                actions={item?.superAdmin ? [<a key='list-loadmore-more'>Super Admin</a>] : [<a key='list-loadmore-more'>{item?.isAdmin ? 'Admin' : 'User'}</a>,
                                <a key='list-loadmore-more' onClick={() => changeRole(item?._id, !item?.isAdmin)}>{item?.isAdmin ? 'Remove Admin' : 'Make Admin'}</a>,
                                <Popconfirm
                                    title={`Delete ${convertTitle(item?.fullName)}'s Account?`}
                                    okText='Delete'
                                    cancelText='Cancel'
                                    okButtonProps={{
                                        type: 'primary',
                                        className: 'form-button'
                                    }}
                                    cancelButtonProps={{ style: { borderRadius: 4 } }}
                                    onConfirm={() => deleteUser(item?._id)}
                                >
                                    <a href="#"><DeleteOutlined style={{ color: 'red' }} /> Delete</a>
                                </Popconfirm>]}
                            >
                                <List.Item.Meta
                                    avatar={
                                        <Avatar src='https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png' />
                                    }
                                    title={<a>{convertTitle(item?.fullName)}</a>}
                                    description={item?.email}
                                />
                                {spin[item?._id] ? <div className='demo-loading-container'>
                                    <Spin />
                                </div> : null}
                            </List.Item>
                        )}
                    />
                </div>
            </div>
        </div >
    )
}

export default Users