import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { List, Button, Spin, Popconfirm, Drawer, Descriptions } from 'antd'
import axios from 'axios'
import moment from 'moment'
import AddTournament from './AddTournament'
import { DeleteOutlined } from '@ant-design/icons'
import { convertTitle, successNotification, convertDate, getAllData } from '../../../utils/helpers'
import { GET, POST } from '../../../utils/apis'

const Tournaments = (props) => {
    const { user, authActions } = props
    const [showForm, setShowForm] = useState(false)
    const allTournaments = useSelector(state => state?.authReducer?.allTournaments) || []
    const [tournament, setTournament] = useState({})
    const [edit, setEdit] = useState(null)
    const [view, setView] = useState(false)
    const [spin, setSpin] = useState({})

    useEffect(() => {
        getAllData(user?._id, authActions)
    }, [showForm])

    const deleteTournament = (_id) => {
        setSpin({ [_id]: true })
        axios.post(`${POST.DELETE_TOURNAMENT}/${user?._id}`, { _id })
            .then((res) => {
                const { data } = res
                setSpin({})
                if (data?.success) {
                    successNotification('Successfully Deleted!')
                    authActions?.addTournamentsList(data?.data || [])
                }
            })
            .catch((e) => {
                setSpin({})
            })
    }

    const showData = (e) => {
        setTournament(e)
        setView(true)
    }

    const showEdit = (e) => {
        e.date[0] = moment(e.date[0])
        e.date[1] = moment(e.date[1])
        setEdit(e)
        setShowForm(true)
    }

    return (
        <div className='home-main'>
            {!showForm ? <div>
                <div className='add-div'>
                    <Button
                        type='primary'
                        className='form-button add-button'
                        onClick={() => setShowForm(true)}
                    >
                        Add Tournament
                    </Button>
                </div>
                <div className='card-box'>
                    <div className='card3'>
                        <List
                            className='demo-loadmore-list'
                            itemLayout='horizontal'
                            pagination={{
                                pageSize: 10,
                                hideOnSinglePage: true
                            }}
                            dataSource={allTournaments}
                            renderItem={item => (
                                <List.Item
                                    actions={[<a key='list-loadmore-more' onClick={() => showData(item)}>View</a>, <a key='list-loadmore-more' onClick={() => showEdit(item)}>Edit</a>,
                                    <Popconfirm
                                        title={`Delete ${item?.name} Tournament?`}
                                        okText='Delete'
                                        cancelText='Cancel'
                                        okButtonProps={{
                                            type: 'primary',
                                            className: 'form-button'
                                        }}
                                        cancelButtonProps={{ style: { borderRadius: 4 } }}
                                        onConfirm={() => deleteTournament(item?._id)}
                                    >
                                        <a href="#"><DeleteOutlined style={{ color: 'red' }} /> Delete</a>
                                    </Popconfirm>]}
                                >
                                    <List.Item.Meta
                                        title={<a>{item?.name}</a>}
                                        description={convertTitle(item?.country)}
                                    />
                                    {spin[item?._id] ? <div className='demo-loading-container'>
                                        <Spin />
                                    </div> : null}
                                </List.Item>
                            )}
                        />
                    </div>
                </div>
                {view ? <Drawer
                    title={`${tournament?.name} Tournament`}
                    placement={'right'}
                    closable={true}
                    onClose={() => setView(false)}
                    visible={true}
                    width={window.innerWidth < 500 ? 340 : 440}
                >
                    <Descriptions title='Tournament Info' bordered column={1}>
                        <Descriptions.Item label={'Tournament'} >{tournament?.name}</Descriptions.Item>
                        <Descriptions.Item label={'Score'}>{tournament?.score}</Descriptions.Item>
                        <Descriptions.Item label={'Surface'}>{tournament?.surface}</Descriptions.Item>
                        <Descriptions.Item label={'Type'}>{tournament?.type}</Descriptions.Item>
                        <Descriptions.Item label={'Venue'}>{tournament?.venue}</Descriptions.Item>
                        <Descriptions.Item label={'Country'}>{convertTitle(tournament?.country)}</Descriptions.Item>
                        <Descriptions.Item label={'City'}>{tournament?.city}</Descriptions.Item>
                        <Descriptions.Item label={'Start Date'}>{convertDate(tournament?.date[0])}</Descriptions.Item>
                        <Descriptions.Item label={'End Date'}>{convertDate(tournament?.date[1])}</Descriptions.Item>
                        <Descriptions.Item label={'Created At'}>{convertDate(tournament?.created)}</Descriptions.Item>
                    </Descriptions>
                </Drawer> : null}
            </div> : <AddTournament {...props} edit={edit} onCancel={() => (setShowForm(false), setEdit(null))} />
            }
        </div >
    )
}

export default Tournaments