import { message, notification } from 'antd'
import axios from 'axios'
import moment from 'moment'
import allPaths from '../Config/paths'
import { AUTH, GET } from './apis'

const requiredMessage = (value) => `Please input your ${value}!`

const inputPlace = (value) => `Input your ${value} Here...!`

const setActiveMenu = (path) => path === allPaths.HOME ? '0' : path === allPaths.SETTINGS ? 1 : path === allPaths.TRANSFER ? 2 : 3

const successMessage = (desc = 'Successfully Complete!') => {
    return message.success(desc)
}

const infoMessage = (desc = 'Successfully Complete!') => {
    return message.info(desc)
}

const errorMessage = (desc = 'Oops Something Went Wrong!') => {
    return message.error(desc)
}

const warningMessage = (desc = 'Warning!') => {
    return message.warning(desc)
}

const successNotification = (message = 'Successfully Complete!') => {
    return notification.success({ message })
}

const errorNotification = (message = 'Oops Something Went Wrong!') => {
    return notification.error({ message })
}

const userObject = (result) => {
    const { profileObj } = result
    return {
        email: profileObj.email,
        fullName: `${profileObj.givenName} ${profileObj.familyName}`,
        uid: profileObj.googleId
    }
}

const facebookLogin = (result, history, loginUser) => {
    const obj = {
        email: result?.email,
        uid: result.id,
        fullName: result.name,
    }
    axios.post(AUTH.SOCIAL_LOGIN, obj)
        .then((res) => {
            const { data } = res
            if (data.success) {
                loginUser(data.user)
                successMessage('Successfully Logged In!')
                return setTimeout(() => {
                    history.push('/')
                }, 300)
            }
            errorMessage(data.message)
        })
        .catch((e) => {
            errorMessage()
        })
}

const googleLogin = (result, history, loginUser) => {
    const obj = userObject(result)
    axios.post(AUTH.SOCIAL_LOGIN, obj)
        .then((res) => {
            const { data } = res
            if (data.success) {
                loginUser(data.user)
                successMessage('Successfully Logged In!')
                return setTimeout(() => {
                    history.push('/')
                }, 300)
            }
            errorMessage(data.message)
        })
        .catch((e) => {
            errorMessage()
        })
}

const convertTitle = (val) => val.charAt(0).toUpperCase() + val.slice(1)

const convertDate = (m) => moment(m)?.format('DD-MM-YYYY')

const getAllData = async (userId, authActions) => {
    let allPromise = []

    allPromise.push(Promise.resolve(axios.get(`${GET.MATCHES}/${userId}`)
        .then((res) => {
            const { data } = res
            authActions?.addMatchesList(data?.data || [])
        })))

    allPromise.push(Promise.resolve(axios.get(`${GET.ALL_USERS}/${userId}`)
        .then((res) => {
            const { data } = res
            authActions?.addUsersList(data?.data || [])
        })))

    allPromise.push(Promise.resolve(axios.get(`${GET.PLAYERS}/${userId}`)
        .then((res) => {
            const { data } = res
            authActions?.addPlayersList(data?.data || [])
        })))

    allPromise.push(Promise.resolve(axios.get(`${GET.TORUNAMENTS}/${userId}`)
        .then((res) => {
            const { data } = res
            authActions?.addTournamentsList(data?.data || [])
        })))

    await Promise.all(allPromise)
}

export {
    requiredMessage,
    inputPlace,
    setActiveMenu,
    successMessage,
    infoMessage,
    errorMessage,
    warningMessage,
    successNotification,
    errorNotification,
    facebookLogin,
    googleLogin,
    convertTitle,
    convertDate,
    getAllData
}