import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { Form, Input, Button } from 'antd'
import { requiredMessage, inputPlace, successMessage, errorMessage } from '../../utils/helpers'
import { removeUser } from '../../Redux/actions/authActions'
import { AUTH } from '../../utils/apis'
import axios from 'axios'

const ForgotPassword = (props) => {
    const dispatch = useDispatch()
    const [loading, setLoading] = useState(false)
    const [btnText, setBtnText] = useState('Search')

    const onFinish = (value) => {
        setLoading(true)
        setBtnText('Searching...')
        axios.post(AUTH.FORGOT_PASSWORD, { email: value?.email })
            .then((result) => {
                const { data } = result
                setLoading(false)
                setBtnText('Search')
                if (data.success) {
                    return successMessage(data.message)
                }
                errorMessage(data.message)
            })
            .catch((err) => {
                setLoading(false)
                setBtnText('Search')
                errorMessage('Something went wrong, Please try again!')
            })
    }

    useEffect(() => {
        dispatch(removeUser())
    }, [])

    return (
        <div className='card-parent'>
            <div className='card1'>
                <p className='heading head-center'>Reset Password</p>
                <Form
                    name='form'
                    onFinish={onFinish}
                    layout={'vertical'}
                    requiredMark={false}
                >
                    <Form.Item
                        name='email'
                        label='Email'
                        hasFeedback
                        rules={[
                            {
                                required: true,
                                message: requiredMessage('Email')
                            },
                            {
                                type: 'email'
                            }
                        ]}
                    >
                        <Input
                            className='form-input'
                            placeholder={inputPlace('email')}
                        />
                    </Form.Item>
                    <Form.Item>
                        <Button
                            type='primary'
                            className='form-button'
                            block
                            htmlType='submit'
                            loading={loading}
                        >
                            {btnText}
                        </Button>
                        <br />
                        <br />
                        <p>Already have account? <Link to='/login'>Login</Link>
                            <br />
                        Don't have an account? <Link to='/register'>Sign up</Link></p>
                    </Form.Item>
                </Form>
            </div>
        </div>
    )
}

export default ForgotPassword