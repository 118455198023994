import React, { useState, useEffect } from 'react'
import { Form, Button, Input, DatePicker, Select, InputNumber } from 'antd'
import axios from 'axios'
import { inputPlace, requiredMessage, convertTitle, successNotification, errorMessage } from '../../../utils/helpers'
import { POST } from '../../../utils/apis'
import moment from 'moment'

const { Option } = Select

// const playerStyle = ['defender', 'offender', 'serv volley', 'hard hitter', 'topspin', 'double backend']
const handed = ['left', 'right']
const backEnd = ['two handed', 'single handed']
const playerStyle = ['offender baseline', 'defender baseline', 'offender']
const service = ['kick', 'top slice', 'flat']

const AddPlayer = (props) => {
    const { user, onCancel, edit } = props
    const [loading, setLoading] = useState(false)
    const [countries, setCountries] = useState([])
    let ageLimit = moment()
    ageLimit.set('year', ageLimit.get('year') - 15)

    useEffect(() => {
        axios.get('https://raw.githubusercontent.com/mansoor876/Countries/main/countries.json')
            .then((req) => {
                setCountries(Object.keys(req?.data))
            })
    }, [])

    const onFinish = (values) => {
        setLoading(true)
        values.userId = user?._id
        edit?._id && (values._id = edit?._id)
        axios.post(POST.ADD_PLAYER, values)
            .then((res) => {
                const { data } = res
                setLoading(false)
                if (data.success) {
                    successNotification('Successfully Created!')
                    return setTimeout(() => {
                        onCancel()
                    }, 300)
                }
                errorMessage(data.message)
            })
            .catch((e) => {
                setLoading(false)
                errorMessage()
            })
    }

    const disabledDate = (current) => {
        return current && current > ageLimit.endOf('day')
    }

    return (
        <div style={{ paddingBottom: 40 }}>
            <div className='card-form'>
                <div className='card2'>
                    <p className='heading head-center'>{edit ? 'Update' : 'Create'} Player</p>
                    <Form
                        name='form'
                        onFinish={onFinish}
                        layout={'vertical'}
                        requiredMark={false}
                    >
                        <Form.Item
                            name='name'
                            label='Player Name'
                            hasFeedback
                            initialValue={edit?.name}
                            rules={[
                                {
                                    required: true,
                                    message: requiredMessage('Player Name')
                                },
                                {
                                    max: 50
                                }
                            ]}
                        >
                            <Input
                                className='form-input'
                                placeholder={inputPlace('Player Name')}
                            />
                        </Form.Item>
                        <div className='flex-row flex-mobile'>
                            <Form.Item
                                name='dob'
                                label='Date of Birth'
                                className='form-layout left-layout'
                                hasFeedback
                                initialValue={edit?.dob || ageLimit}
                            >
                                <DatePicker disabledDate={disabledDate} className='form-input' style={{ width: '100%' }} />
                            </Form.Item>
                            <Form.Item
                                name='service'
                                label='Service'
                                className='form-layout right-layout'
                                initialValue={edit?.service}
                                hasFeedback
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please Select Service'
                                    }
                                ]}
                            >
                                <Select
                                    showSearch
                                    showArrow
                                    allowClear
                                    className='form-input'
                                    placeholder='Select Service'
                                    optionFilterProp='children'
                                    filterOption={(input, option) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                >
                                    {service?.map((v, i) => <Option value={v} key={i}>{convertTitle(v)}</Option>)}
                                </Select>
                            </Form.Item>
                        </div>
                        <div className='flex-row flex-mobile'>
                            <Form.Item
                                name='country'
                                label='Country'
                                className='form-layout left-layout'
                                initialValue={edit?.country}
                                hasFeedback
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please Select Country'
                                    }
                                ]}
                            >
                                <Select
                                    showSearch
                                    showArrow
                                    className='form-input'
                                    placeholder='Select Country'
                                    optionFilterProp='children'
                                    filterOption={(input, option) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                >
                                    {countries?.map((v, i) => <Option value={v} key={i}>{convertTitle(v)}</Option>)}
                                </Select>
                            </Form.Item>
                            <Form.Item
                                name='style'
                                label='Player Style'
                                className='form-layout right-layout'
                                initialValue={edit?.style}
                                hasFeedback
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please Select Player style'
                                    }
                                ]}
                            >
                                <Select
                                    showSearch
                                    showArrow
                                    allowClear
                                    className='form-input'
                                    mode='multiple'
                                    placeholder='Select Player style'
                                    optionFilterProp='children'
                                    filterOption={(input, option) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                >
                                    {playerStyle?.map((v, i) => <Option value={v} key={i}>{convertTitle(v)}</Option>)}
                                </Select>
                            </Form.Item>
                        </div>
                        <div className='flex-row flex-mobile'>
                            <Form.Item
                                name='hand'
                                label='Handed'
                                className='form-layout left-layout'
                                initialValue={edit?.hand}
                                hasFeedback
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please Select Player Hand Side'
                                    }
                                ]}
                            >
                                <Select
                                    showSearch
                                    showArrow
                                    className='form-input'
                                    placeholder='Select Player Hand Side'
                                    optionFilterProp='children'
                                    filterOption={(input, option) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                >
                                    {handed?.map((v, i) => <Option value={v} key={i}>{convertTitle(v)}</Option>)}
                                </Select>
                            </Form.Item>
                            <Form.Item
                                name='backHand'
                                label='Backhand'
                                initialValue={edit?.backHand}
                                className='form-layout right-layout'
                                hasFeedback
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please Select Backhand Hand Side'
                                    }
                                ]}
                            >
                                <Select
                                    showSearch
                                    showArrow
                                    className='form-input'
                                    placeholder='Select Backend Hand Side'
                                    optionFilterProp='children'
                                    filterOption={(input, option) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                >
                                    {backEnd?.map((v, i) => <Option value={v} key={i}>{convertTitle(v)}</Option>)}
                                </Select>
                            </Form.Item>
                        </div>
                        <div className='flex-row flex-mobile'>
                            <Form.Item
                                name='height'
                                label='Height (cm)'
                                className='form-layout left-layout'
                                hasFeedback
                                initialValue={edit?.height}
                                rules={[
                                    {
                                        required: true,
                                        message: requiredMessage('Player Height')
                                    }
                                ]}
                            >
                                <InputNumber
                                    className='form-input'
                                    min={1}
                                    max={220}
                                    style={{ width: '100%' }}
                                    placeholder={inputPlace('Player Height')}
                                />
                            </Form.Item>
                            <Form.Item
                                name='turnedPro'
                                label='Turned Pro'
                                className='form-layout right-layout'
                                hasFeedback
                                initialValue={edit?.turnedPro}
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please Select Turned Pro'
                                    }
                                ]}
                            >
                                <DatePicker className='form-input' picker='year' style={{ width: '100%' }} />
                            </Form.Item>
                        </div>
                        <Form.Item>
                            <Button
                                type='primary'
                                className='form-button'
                                block
                                htmlType='submit'
                                loading={loading}
                            >
                                {edit ? 'Update' : 'Create'}
                            </Button>
                            <Button
                                style={{ borderRadius: 4, marginTop: 6 }}
                                block
                                onClick={onCancel}
                            >
                                Cancel
                            </Button>
                        </Form.Item>
                    </Form>
                </div>
            </div>
        </div>
    )
}

export default AddPlayer