import React, { useState, useEffect, useRef } from 'react'
import { Form, Button, DatePicker, Select, InputNumber } from 'antd'
import axios from 'axios'
import moment from 'moment'
import { GET, POST } from '../../utils/apis'
import { requiredMessage, convertTitle, errorMessage, successNotification, inputPlace } from '../../utils/helpers'

const { Option } = Select

const servingSide = ['top', 'bottom']
const scoringType = ['points', 'bounce', 'hitting']

const AddMatch = (props) => {
    const { user, onCancel } = props
    const [loading, setLoading] = useState(false)
    const [countries, setCountries] = useState([])
    const [tournaments, setTournaments] = useState([])
    const [players, setPlayers] = useState([])
    const [servingPlayers, setServingPlayers] = useState([])
    const antdForm = useRef(null)

    useEffect(() => {
        getData()
        axios.get('https://raw.githubusercontent.com/mansoor876/Countries/main/countries.json')
            .then((req) => {
                setCountries(Object.keys(req?.data))
            })
    }, [])

    const getData = () => {
        axios.get(GET.TORUNAMENTS)
            .then((req) => {
                const { data } = req
                setTournaments(data?.data || [])
            })
        axios.get(GET.PLAYERS)
            .then((req) => {
                const { data } = req
                setPlayers(data?.data || [])
            })
    }

    const updateServing = () => {
        const { getFieldValue } = antdForm.current
        let uniqueValues = [getFieldValue('player1'), getFieldValue('player2')]?.filter(v => v)
        uniqueValues = [...new Set(uniqueValues)]
        setServingPlayers(players?.filter(v => uniqueValues?.indexOf(v?._id) !== -1))
    }

    const onFinish = (values) => {
        setLoading(true)
        values.userId = user?._id
        values.tournament = tournaments?.filter(v => v?._id === values.tournamentName)[0]
        values.player1 = players?.filter(v => v?._id === values.player1)[0]
        values.player2 = players?.filter(v => v?._id === values.player2)[0]
        values.player1.atpRanking = values?.atpRanking1
        values.player2.atpRanking = values?.atpRanking2
        axios.post(POST.ADD_MATCH, values)
            .then((res) => {
                const { data } = res
                setLoading(false)
                if (data.success) {
                    successNotification('Successfully Created!')
                    return setTimeout(() => {
                        onCancel()
                    }, 300)
                }
                // errorMessage(data.message)
            })
            .catch((e) => {
                setLoading(false)
                errorMessage()
            })
    }

    const disabledDate = (current) => {
        return current && current < moment().startOf('day')
    }

    return (
        <div style={{ paddingBottom: 40 }}>
            <div className='card-form'>
                <div className='card2'>
                    <p className='heading head-center'>Add Match</p>
                    <Form
                        name='form'
                        onFinish={onFinish}
                        layout={'vertical'}
                        requiredMark={false}
                        ref={antdForm}
                    >
                        <Form.Item
                            name='tournamentName'
                            label='Tournament Name'
                            hasFeedback
                            rules={[
                                {
                                    required: true,
                                    message: requiredMessage('Tournament Name')
                                },
                                {
                                    max: 50
                                }
                            ]}
                        >
                            <Select
                                showSearch
                                showArrow
                                className='form-input'
                                placeholder='Select Tournament Name'
                                optionFilterProp='children'
                                filterOption={(input, option) =>
                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                            >
                                {tournaments?.map((v, i) => <Option value={v?._id} key={i}>{convertTitle(v?.name)}</Option>)}
                            </Select>
                        </Form.Item>
                        <div className='flex-row flex-mobile'>
                            <Form.Item
                                name='matchDate'
                                label='Match Date'
                                hasFeedback
                                className='form-layout left-layout'
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please Select Match Date'
                                    }
                                ]}
                            >
                                <DatePicker disabledDate={disabledDate} className='form-input' style={{ width: '100%' }} />
                            </Form.Item>
                            <Form.Item
                                name='scoring'
                                label='Scoring'
                                className='form-layout right-layout'
                                hasFeedback
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please Scoring!'
                                    }
                                ]}
                            >
                                <Select
                                    showSearch
                                    showArrow
                                    className='form-input'
                                    placeholder='Select Scoring'
                                    optionFilterProp='children'
                                    filterOption={(input, option) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                >
                                    {scoringType?.map((v, i) => <Option value={v} key={i}>{convertTitle(v)}</Option>)}
                                </Select>
                            </Form.Item>
                        </div>
                        <div className='flex-row flex-mobile'>
                            <Form.Item
                                name='player1'
                                label='Player 1'
                                className='form-layout left-layout'
                                hasFeedback
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please Select Player 1!'
                                    },
                                    ({ getFieldValue }) => ({
                                        validator(rule, value) {
                                            if (!value || getFieldValue('player2') !== value) {
                                                return Promise.resolve()
                                            }

                                            return Promise.reject('Please Select Different Player!')
                                        },
                                    })
                                ]}
                            >
                                <Select
                                    showSearch
                                    showArrow
                                    className='form-input'
                                    placeholder='Select Player 1'
                                    optionFilterProp='children'
                                    onChange={updateServing}
                                    filterOption={(input, option) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                >
                                    {players?.map((v, i) => <Option value={v?._id} key={i}>{convertTitle(v?.name)}</Option>)}
                                </Select>
                            </Form.Item>
                            <Form.Item
                                name='player2'
                                label='Player 2'
                                className='form-layout right-layout'
                                hasFeedback
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please Select Player 2!'
                                    },
                                    ({ getFieldValue }) => ({
                                        validator(rule, value) {
                                            if (!value || getFieldValue('player1') !== value) {
                                                return Promise.resolve()
                                            }

                                            return Promise.reject('Please Select Different Player!')
                                        },
                                    })
                                ]}
                            >
                                <Select
                                    showSearch
                                    showArrow
                                    className='form-input'
                                    placeholder='Select Player 2'
                                    optionFilterProp='children'
                                    onChange={updateServing}
                                    filterOption={(input, option) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                >
                                    {players?.map((v, i) => <Option value={v?._id} key={i}>{convertTitle(v?.name)}</Option>)}
                                </Select>
                            </Form.Item>
                        </div>
                        <div className='flex-row flex-mobile'>
                            <Form.Item
                                name='atpRanking1'
                                label='Player 1 Atp Ranking'
                                className='form-layout left-layout'
                                hasFeedback
                                rules={[
                                    {
                                        required: true,
                                        message: requiredMessage('Player 1 Atp Ranking')
                                    },
                                    ({ getFieldValue }) => ({
                                        validator(rule, value) {
                                            if (!value || getFieldValue('atpRanking2') !== value) {
                                                return Promise.resolve()
                                            }

                                            return Promise.reject("ATP Ranking can't be same")
                                        }
                                    })
                                ]}
                            >
                                <InputNumber
                                    className='form-input'
                                    min={1}
                                    max={220}
                                    style={{ width: '100%' }}
                                    placeholder={inputPlace('Player 1 Atp Ranking')}
                                />
                            </Form.Item>
                            <Form.Item
                                name='atpRanking2'
                                label='Player 2 Atp Ranking'
                                className='form-layout left-layout'
                                hasFeedback
                                rules={[
                                    {
                                        required: true,
                                        message: requiredMessage('Player 2 Atp Ranking')
                                    },
                                    ({ getFieldValue }) => ({
                                        validator(rule, value) {
                                            if (!value || getFieldValue('atpRanking1') !== value) {
                                                return Promise.resolve()
                                            }

                                            return Promise.reject("ATP Ranking can't be same")
                                        }
                                    })
                                ]}
                            >
                                <InputNumber
                                    className='form-input'
                                    min={1}
                                    max={220}
                                    style={{ width: '100%' }}
                                    placeholder={inputPlace('Player 2 Atp Ranking')}
                                />
                            </Form.Item>
                        </div>
                        <div className='flex-row flex-mobile'>
                            <Form.Item
                                name='startServing'
                                label='Start Serving'
                                className='form-layout left-layout'
                                hasFeedback
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please Start Serving!'
                                    }
                                ]}
                            >
                                <Select
                                    showSearch
                                    showArrow
                                    className='form-input'
                                    placeholder='Start Serving'
                                    optionFilterProp='children'
                                    filterOption={(input, option) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                >
                                    {servingPlayers?.map((v, i) => <Option value={v?._id} key={i}>{convertTitle(v?.name)}</Option>)}
                                </Select>
                            </Form.Item>
                            <Form.Item
                                name='servingSide'
                                label={<p style={{ marginBottom: -10 }}>Serving Side 1<sup>st</sup> game</p>}
                                className='form-layout right-layout'
                                hasFeedback
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please Select Serving Side!'
                                    }
                                ]}
                            >
                                <Select
                                    showSearch
                                    showArrow
                                    allowClear
                                    className='form-input'
                                    placeholder='Select Serving Side'
                                    optionFilterProp='children'
                                    filterOption={(input, option) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                >
                                    {servingSide?.map((v, i) => <Option value={v} key={i}>{convertTitle(v)}</Option>)}
                                </Select>
                            </Form.Item>
                        </div>
                        <Form.Item>
                            <Button
                                type='primary'
                                className='form-button'
                                block
                                htmlType='submit'
                                loading={loading}
                            >
                                Submit
                            </Button>
                            <Button
                                style={{ borderRadius: 4, marginTop: 6 }}
                                block
                                onClick={onCancel}
                            >
                                Cancel
                            </Button>
                        </Form.Item>
                    </Form>
                </div>
            </div>
        </div>
    )
}

export default AddMatch