import React from 'react'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import { Login, Signup, MatchTool, ForgotPassword, UpdatePassword, VerifyEmail, Admin, Matches } from '../Screens'
import { MenuLayout } from '../Components'
import allPaths from './paths'
import { Result, Button } from 'antd'

const Page404 = (props) => {
    const { history } = props
    return (
        <Result
            status='404'
            title='404'
            subTitle='Sorry, the page you visited does not exist.'
            extra={<Button
                type='primary'
                className='form-button'
                onClick={() => history.push('/')}
            >Back Home</Button>}
        />
    )
}

const Routes = () => {
    return (
        <Router>
            <Switch>
                <Route path={allPaths?.LOGIN} exact component={Login} />
                <Route path={allPaths?.SIGNUP} exact component={Signup} />
                <Route path={allPaths?.FORGOT} exact component={ForgotPassword} />
                <Route path={`${allPaths?.UPDATE_PASSWORD}/:token`} exact component={UpdatePassword} />
                <Route path={`${allPaths?.VERIFY_EMAIL}/:token`} exact component={VerifyEmail} />
                <MenuLayout path={allPaths?.HOME} exact component={Matches} />
                <MenuLayout path={allPaths?.MATCH_TOOL} exact component={MatchTool} />
                <MenuLayout path={allPaths?.ADMIN} exact component={Admin} />
                <Route path='/:page404' exact component={Page404} />
            </Switch>
        </Router>
    )
}

export {
    Routes,
    Page404
}