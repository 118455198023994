import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { List, Button, Spin, Popconfirm, Drawer, Descriptions } from 'antd'
import axios from 'axios'
import AddMatch from './AddMatch'
import { DeleteOutlined } from '@ant-design/icons'
import { successNotification, convertDate, getAllData } from '../../utils/helpers'
import { GET, POST } from '../../utils/apis'
import allPaths from '../../Config/paths'

const Matches = (props) => {
    const { user, matchActions, history, authActions } = props
    const allMatches = useSelector(state => state?.authReducer?.allMatches) || []
    const [showForm, setShowForm] = useState(false)
    const [match, setMatch] = useState({})
    const [view, setView] = useState(false)
    const [spin, setSpin] = useState({})

    useEffect(() => {
        getAllData(user?._id, authActions)
    }, [showForm])

    const deleteMatch = (_id) => {
        setSpin({ [_id]: true })
        axios.post(`${POST.DELETE_MATCH}/${user?._id}`, { _id })
            .then((res) => {
                const { data } = res
                setSpin({})
                if (data?.success) {
                    successNotification('Successfully Deleted!')
                    authActions?.addMatchesList(data?.data || [])
                }
            })
            .catch((e) => {
                setSpin({})
            })
    }

    const showData = (e) => {
        e.startServing = e?.player1?._id === e?.startServing ? e?.player1?.name : e?.player2?.name
        setMatch(e)
        setView(true)
    }

    const startMatch = (e) => {
        matchActions?.startMatch(e)
        history?.push(allPaths?.MATCH_TOOL)
    }

    const getWinner = (id, item) => {
        const { player1, player2 } = item
        return id === player1?._id ? player1?.name : player2?.name
    }

    const getScore = () => {
        const { playersScore } = match
        return `${playersScore?.player1?.set}-${playersScore?.player2?.set}`
    }

    return (
        <div className='home-main'>
            {!showForm ? <div>
                <div className='add-div'>
                    <Button
                        type='primary'
                        className='form-button add-button'
                        onClick={() => setShowForm(true)}
                    >
                        Add Match
                    </Button>
                </div>
                <div className='card-box'>
                    <div className='card3'>
                        <List
                            className='demo-loadmore-list'
                            itemLayout='horizontal'
                            pagination={{
                                pageSize: 10,
                                hideOnSinglePage: true
                            }}
                            dataSource={allMatches}
                            renderItem={(item, i) => (
                                <List.Item
                                    actions={[item?.winner ? <a key='list-loadmore-more'>Winner: {getWinner(item?.winner, item)}</a> : <a key='list-loadmore-more' onClick={() => startMatch(item)}>{item?.playersScore ? 'Resume' : 'Start'}</a>, <a key='list-loadmore-more' onClick={() => showData(item)}>View</a>,
                                    <Popconfirm
                                        title={`Delete Match?`}
                                        okText='Delete'
                                        cancelText='Cancel'
                                        okButtonProps={{
                                            type: 'primary',
                                            className: 'form-button'
                                        }}
                                        cancelButtonProps={{ style: { borderRadius: 4 } }}
                                        onConfirm={() => deleteMatch(item?._id)}
                                    >
                                        <a href="#"><DeleteOutlined style={{ color: 'red' }} /> Delete</a>
                                    </Popconfirm>]}
                                >
                                    <List.Item.Meta
                                        title={<a>{i + 1}) {convertDate(item?.matchDate)} ({item?.player1?.name}, {item?.player2?.name})</a>}
                                    />
                                    {spin[item?._id] ? <div className='demo-loading-container'>
                                        <Spin />
                                    </div> : null}
                                </List.Item>
                            )}
                        />
                    </div>
                </div>
                {view ? <Drawer
                    title={`Match`}
                    placement={'right'}
                    closable={true}
                    onClose={() => setView(false)}
                    visible={true}
                    width={window.innerWidth < 500 ? 340 : 440}
                >
                    <Descriptions title='Match Info' bordered column={1}>
                        <Descriptions.Item label={'Name'}>{match?.tournament?.name}</Descriptions.Item>
                        <Descriptions.Item label={'Player 1'}>{match?.player1?.name}</Descriptions.Item>
                        <Descriptions.Item label={'Player 1 Atp Ranking'}>{match?.player1?.atpRanking || null}</Descriptions.Item>
                        <Descriptions.Item label={'Player 2'}>{match?.player2?.name}</Descriptions.Item>
                        <Descriptions.Item label={'Player 2 Atp Ranking'}>{match?.player2?.atpRanking || null}</Descriptions.Item>
                        <Descriptions.Item label={'Serving Side'}>{match?.servingSide}</Descriptions.Item>
                        <Descriptions.Item label={'Start Serving'}>{match?.startServing}</Descriptions.Item>
                        <Descriptions.Item label={'Match Date'}>{convertDate(match?.matchDate)}</Descriptions.Item>
                        <Descriptions.Item label={'Winner'}>{match?.winner ? getWinner(match?.winner, match) : ''}</Descriptions.Item>
                        <Descriptions.Item label={'Point'}>{`${match?.playersScore?.player1?.point}-${match?.playersScore?.player2?.point}`}</Descriptions.Item>
                        <Descriptions.Item label={'Game Score'}>{match?.gamePoint?.length ? `${match?.gamePoint?.map(v => v?.join('-'))?.join(';') || ''}${match?.playersScore?.player1?.game || match?.playersScore?.player2?.game ? `;${match?.playersScore?.player1?.game}-${match?.playersScore?.player2?.game}` : ''}` : `${match?.playersScore?.player1?.game}-${match?.playersScore?.player2?.game}`}</Descriptions.Item>
                        <Descriptions.Item label={'Set Score'}>{getScore()}</Descriptions.Item>
                        <Descriptions.Item label={'Created At'}>{convertDate(match?.created)}</Descriptions.Item>
                    </Descriptions>
                </Drawer> : null}
            </div> : <AddMatch {...props} onCancel={() => (setShowForm(false))} />
            }
        </div >
    )
}

export default Matches