import { START_MATCH, STOP_MATCH, SET_FAULT } from '../types'

const reducer = (state = {}, action) => {
    switch (action.type) {
        case START_MATCH: {
            return { ...state, match: action.match }
        }
        case SET_FAULT: {
            return { ...state, fault: action.fault }
        }
        case STOP_MATCH: {
            return { ...state, match: null }
        }
        default: {
            return state
        }
    }
}
export default reducer