import React, { useState } from 'react'
import { GoogleLogin } from 'react-google-login'
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props'
import { GoogleLoginButton, FacebookLoginButton } from 'react-social-login-buttons'
import { googleClientId, facebookId } from '../../utils/constants'
import { googleLogin, facebookLogin } from '../../utils/helpers'

const SocialButtons = (props) => {
    const { history, loginUser } = props

    return (
        <div className='social-buttons'>
            <GoogleLogin
                clientId={googleClientId}
                render={renderProps => <GoogleLoginButton
                    onClick={renderProps.onClick}
                    className='social-login google-btn'
                />}
                onSuccess={(e) => googleLogin(e, history, loginUser)}
                onFailure={(e) => console.log('e', e)}
                style={{ display: 'inline' }}
                cookiePolicy={'single_host_origin'}
            />
            {/* <FacebookLogin
                fields='name,email,picture'
                render={renderProps => <FacebookLoginButton
                    onClick={renderProps.onClick}
                    className='social-login fb-btn'
                />}
            /> */}
        </div>
    )
}

export default SocialButtons