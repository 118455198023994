const authApi = `/api/user`
const getApi = `/api/get`
const postApi = `/api/post`

const AUTH = {
    LOGIN: `${authApi}/login`,
    SIGNUP: `${authApi}/signup`,
    CHANGE_PASSWORD: `${authApi}/change-password`,
    CHANGE_ROLE: `${authApi}/change-role`,
    DELETE_ACCOUNT: `${authApi}/delete-account`,
    FORGOT_PASSWORD: `${authApi}/forgot-password`,
    UPDATE_PASSWORD: `${authApi}/update-password`,
    SOCIAL_LOGIN: `${authApi}/social-login`
}

const GET = {
    USER_BY_ID: `${getApi}/get-user`,
    ALL_USERS: `${getApi}/get-all-users`,
    VERIFY_TOKEN: `${getApi}/verifytoken`,
    TORUNAMENTS: `${getApi}/get-tournaments`,
    PLAYERS: `${getApi}/get-players`,
    MATCHES: `${getApi}/get-matches`,
    MATCH: `${getApi}/get-match`,
    VERIFY_EMAIL: `${getApi}/verifyemail`
}

const POST = {
    UPDATE_SETTINGS: `${postApi}/update-settings`,
    ADD_PLAYER: `${postApi}/add-player`,
    ADD_TOURNAMENT: `${postApi}/add-tournament`,
    ADD_STROKE: `${postApi}/add-stroke`,
    ADD_EXTRA: `${postApi}/add-extra`,
    ADD_MATCH: `${postApi}/add-match`,
    DELETE_PLAYER: `${postApi}/delete-player`,
    DELETE_TOURNAMENT: `${postApi}/delete-tournamnet`,
    DELETE_MATCH: `${postApi}/delete-match`,
    UPDATE_SCORE: `${postApi}/update-score`
}

export {
    AUTH,
    GET,
    POST
}