import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { Button, Modal, Radio, Space, Spin } from 'antd'
import axios from 'axios'
import { midTop, midTop2, midTop3, midTop4, midTop4Child, midBottom, midBottom2, midBottom3, midBottom4, tennisCorners, midBottom4Child, midTop4Child2, midBottom4Child2, oSection, SectionIn, extraButtons } from '../../utils/constants'
import { POST, GET } from '../../utils/apis'
import { convertTitle, errorMessage, successNotification, warningMessage } from '../../utils/helpers'
import allPaths from '../../Config/paths'

const borderRadius = { borderRadius: 4 }

const AllInSection = [...new Set(Object.values(SectionIn)?.flatMap(v => typeof (v) === 'object' ? Object?.values(v)?.flat() : v))]

const MatchTool = (props) => {
    const { history, matchActions, user } = props
    let newArr = [{ key: 0, value: 0 }, { key: 15, value: 15 }]
    const match = useSelector(state => state?.matchReducer?.match)
    const [point1, setPoint1] = useState(newArr)
    const [point2, setPoint2] = useState(newArr)
    const [tie1, setTie1] = useState([0, 1])
    const [tie2, setTie2] = useState([0, 1])
    const [activeId, setId] = useState(null)
    const [serve, setServe] = useState({})
    const [noServe, setNoServe] = useState({})
    const [showModal, setShowModal] = useState(false)
    const [stopReason, setStopReason] = useState('ref')
    const [loading, setLoading] = useState(false)
    const [spin, setSpin] = useState({})

    useEffect(() => {
        // initScore()
        getMatch()
    }, [])

    useEffect(() => {
        window.addEventListener('resize', checkWindowWidth)
        checkWindowWidth()
        updateServing()
        updateInSections()
        updateData()
        adminConsole('match', match)
        setTimeout(() => {
            handleActive()
        }, 200)
    }, [match])

    const updateData = () => {
        if (!match) {
            history?.push(allPaths?.HOME)
        }
        if (match?.playersScore && !match?.tieBreak) {
            updateOptions('player1', match?.playersScore?.player1?.point || 0, false)
            updateOptions('player2', match?.playersScore?.player2?.point || 0, false)
        }
        else if (match?.tieBreak) {
            updateTieBreak('player1', match?.playersScore?.player1?.tiePoint || 0)
            updateTieBreak('player2', match?.playersScore?.player2?.tiePoint || 0)
        }
        else {
            initScore()
        }
    }

    const updateInSections = () => {
        let sectionIn, totalPoints, side, btn

        if (match?.scoring !== 'points') {

            for (var s of AllInSection) {
                btn = document.getElementById(s)
                btn.className = btn?.className?.replace('in-section', '')
            }

            if (!match?.playersScore) {
                side = 'right'
                totalPoints = 0
            }
            else {
                totalPoints = !match?.tieBreak ? match?.playersScore?.player1?.point + match?.playersScore?.player2?.point : match?.playersScore?.player1?.tiePoint + match?.playersScore?.player2?.tiePoint
                side = totalPoints > 80 || totalPoints === 70 || totalPoints === 40 ? (side = 'left') : totalPoints % 2 === 0 || totalPoints === 55 ? (side = 'right') : (side = 'left')
            }

            adminConsole('totalPoints', totalPoints)
            adminConsole('side', side)

            if (match?.strokeNo === 1) {
                sectionIn = match?.servingSide === 'top' ? SectionIn?.topFirstServe?.[side] : SectionIn?.bottomFirstServe?.[side]
            }
            else if (match?.strokeNo % 2 === 0) {
                sectionIn = match?.servingSide === 'top' ? SectionIn?.bottomServe : SectionIn?.topServe
            }
            else {
                sectionIn = match?.servingSide === 'top' ? SectionIn?.topServe : SectionIn?.bottomServe
            }

            for (var v of sectionIn) {
                btn = document.getElementById(v)
                btn.className += ' in-section'
            }
        }
    }

    const adminConsole = (key, value) => {
        if (user?.superAdmin) {
            console.log(key, value)
        }
    }

    const getMatch = () => {
        axios.get(`${GET.MATCH}/${match?._id}`)
            .then((res) => {
                const { data } = res
                if (data?.success) {
                    matchActions?.startMatch(data?.match)
                }
                else {
                    history?.push(allPaths?.HOME)
                }
            })
            .catch((e) => {
                history?.push(allPaths?.HOME)
            })
    }

    const checkWindowWidth = () => {
        if (match?.scoring !== 'points') {
            window.innerWidth < 600 && history?.push(allPaths?.HOME)
        }
    }

    const updateServing = () => {
        const { player1, player2, startServing } = match
        setServe(startServing === player1?._id ? player1 : player2)
        setNoServe(startServing !== player1?._id ? player1 : player2)
    }

    const renderList = (list) => {
        return (
            list?.map((v, i) => <Button
                key={i}
                className={`${v.classNames} tennis-button`}
                id={v.name}
                onClick={() => updateStroke(v.name)}
            >
                {v.name?.toLocaleUpperCase()}
            </Button>
            )
        )
    }

    const renderMainList = (list) => {
        return (
            list?.map((v, i) => <Button
                key={i}
                className={`${v.classNames} tennis-button`}
                id={v.name}
                onClick={() => updateStroke(v.name)}
            >
                {v.name?.toLocaleUpperCase()}&nbsp;
            </Button>
            )
        )
    }

    const buttonComp = (v) => (
        <Button
            className={`${v.classNames} tennis-button`}
            id={v.name}
            onClick={() => updateStroke(v.name)}
        >
            {v.name?.toLocaleUpperCase()}
        </Button>
    )

    const handleActive = (v = null) => {
        if (v && v !== activeId) {
            let btn = document.getElementById(v)
            btn.className += ' active-btn'
            setId(v)
        }
        if (activeId) {
            let btn = document.getElementById(activeId)
            btn.className = btn.className.replace('active-btn', '')
        }
    }

    const updateStroke = (v) => {
        const { strokeNo, servingSide, fault, playersScore, player1, tieBreak } = match
        let obj, status, side, key, value

        if (!playersScore) {
            side = 'right'
        }
        else {
            let totalPoints = match?.playersScore?.player1?.point + match?.playersScore?.player2?.point || match?.playersScore?.player1?.tiePoint + match?.playersScore?.player2?.tiePoint
            side = totalPoints > 80 || totalPoints === 70 || totalPoints === 40 ? (side = 'left') : totalPoints % 2 === 0 || totalPoints === 55 ? (side = 'right') : (side = 'left')
        }

        if (strokeNo === 1) {
            status = servingSide === 'top' ? SectionIn?.topFirstServe?.[side]?.indexOf(v) !== -1 ? 'in' : 'out' : SectionIn?.bottomFirstServe?.[side]?.indexOf(v) !== -1 ? 'in' : 'out'

            obj = {
                strokeNo,
                section: v,
                status,
                playerId: serve?._id,
                recId: noServe?._id,
                matchId: match?._id
            }

            if (status === 'out' && !fault) {
                obj.fault = true
            }
            else if (status === 'out') {
                obj.fault = true
                key = obj.recId === player1._id ? 'player1' : 'player2'
            }
        }
        else if (strokeNo % 2 === 0) {
            status = match?.servingSide === 'top' ? SectionIn?.bottomServe?.indexOf(v) !== -1 ? 'in' : 'out' : SectionIn?.topServe?.indexOf(v) !== -1 ? 'in' : 'out'
            obj = {
                strokeNo,
                section: v,
                status,
                playerId: noServe?._id,
                recId: serve?._id,
                matchId: match?._id
            }
            matchActions?.setFault(false)

            if (status === 'out') {
                obj.fault = true
                key = obj.recId === player1._id ? 'player1' : 'player2'
            }
        }
        else {
            status = match?.servingSide === 'top' ? SectionIn?.topServe?.indexOf(v) !== -1 ? 'in' : 'out' : SectionIn?.bottomServe?.indexOf(v) !== -1 ? 'in' : 'out'
            obj = {
                strokeNo,
                section: v,
                status,
                playerId: serve?._id,
                recId: noServe?._id,
                matchId: match?._id
            }
            matchActions?.setFault(false)

            if (status === 'out') {
                obj.fault = true
                obj.matchFault = true
                key = obj.recId === player1._id ? 'player1' : 'player2'
            }
        }

        match?.scoring !== 'points' && handleActive(v)

        obj.matchFault = obj.fault || false

        if (status === 'out') {
            obj.updatedStroke = 1
        }
        else {
            obj.updatedStroke = obj?.fault ? strokeNo : strokeNo + 1
        }

        adminConsole('obj', obj)

        let newMatch = {
            fault: obj?.matchFault,
            strokeNo: obj?.updatedStroke,
            lastPoint: {}
        }

        // matchActions?.startMatch({ ...match, ...newMatch })

        if (key) {
            if (!tieBreak) {
                if (playersScore[key].point === 0 || playersScore[key].point === 15) {
                    value = playersScore[key].point + 15
                }
                else if (playersScore[key].point === 30) {
                    value = playersScore[key].point + 10
                }
                else if (playersScore[key].point === 40) {
                    let totalPoints = match?.playersScore?.player1?.point + match?.playersScore?.player2?.point
                    if (totalPoints === 80) {
                        value = playersScore[key].point + 10
                    }
                    else {
                        value = 0
                    }
                }
                else {
                    value = 0
                }
            }
            else {
                value = playersScore[key].tiePoint + 1
            }

            updatePoints(key, value)
            obj.matchFault = false

        }
        axios.post(POST.ADD_STROKE, obj)
            .then((res) => {
                const { data } = res
                if (data?.success) {
                    matchActions?.startMatch(data?.match)
                }
                else {
                    errorMessage()
                }
            })
    }

    const updatePoints = (key, value, tie = true) => {
        const { playersScore, servingSide, tieBreak, tournament } = match
        const { player1, player2 } = playersScore
        value = Number(value)
        let newVal, diff, totalGames, totalTieBreak, arr, obj

        obj = {
            _id: match?._id,
            playersScore: {
            },
            gamePoint: match?.gamePoint || [],
            tieBreakPoint: match?.tieBreakPoint || [],
            lastPointKey: key,
            lastServingSide: match?.servingSide,
            lastServing: match?.startServing,
            lastTieBreak: tieBreak,
            fault: false
        }

        if (tieBreak && tie) {
            arr = [value, value + 1]

            if (key === 'player1') {

                setTie1(arr)
                obj.playersScore = {
                    player1: {
                        point: 0,
                        tiePoint: value,
                        game: player1?.game,
                        set: player1?.set
                    },
                    player2: {
                        point: 0,
                        tiePoint: player2?.tiePoint,
                        game: player2?.game,
                        set: player2?.set
                    }
                }

                obj.lastPoint = match?.playersScore

                var t1 = obj?.playersScore?.player1?.tiePoint || 0
                var t2 = player2?.tiePoint || 0

                totalTieBreak = t1 + t2
                diff = t1 - t2

                if (t1 > 6 && diff >= 2) {
                    obj?.tieBreakPoint?.push([t1, t2])
                    obj?.gamePoint?.push([7, 6])
                    obj.playersScore.player1.tiePoint = 0
                    obj.playersScore.player2.tiePoint = 0
                    obj.playersScore.player1.game = 0
                    obj.playersScore.player2.game = 0
                    obj.tieBreak = false
                    obj.playersScore.player1.set = player1?.set + 1
                    obj.startServing = match?.player1?._id === match?.tieBreakServing ? match?.player2?._id : match?.player1?._id
                    obj.servingSide = match?.tieBreakServing !== match?.startServing ? servingSide === 'top' ? 'bottom' : 'top' : match?.servingSide
                    setTie1([0, 1])
                    setTie2([0, 1])
                    if (tournament?.score === 3 && obj?.playersScore?.player1?.set === 2 || tournament?.score === 5 && obj?.playersScore?.player1?.set === 3) {
                        obj.winner = match?.player2?._id
                    }
                }
                else {
                    if (totalTieBreak && totalTieBreak % 6 === 0) {
                        obj.servingSide = servingSide === 'top' ? 'bottom' : 'top'
                    }
                    else if (totalTieBreak && totalTieBreak % 2 !== 0) {
                        obj.servingSide = servingSide === 'top' ? 'bottom' : 'top'
                        obj.startServing = match?.player1?._id === match?.startServing ? match?.player2?._id : match?.player1?._id
                    }
                }
            }
            else {
                setTie2(arr)
                obj.playersScore = {
                    player1: {
                        point: 0,
                        tiePoint: player1?.tiePoint,
                        game: player1?.game,
                        set: player1?.set
                    },
                    player2: {
                        point: 0,
                        tiePoint: value,
                        game: player2?.game,
                        set: player2?.set
                    }
                }

                obj.lastPoint = match?.playersScore

                var t1 = player1?.tiePoint || 0
                var t2 = obj?.playersScore?.player2?.tiePoint || 0

                totalTieBreak = t1 + t2
                diff = t2 - t1

                if (t2 > 6 && diff >= 2) {
                    obj?.tieBreakPoint?.push([t1, t2])
                    obj?.gamePoint?.push([6, 7])
                    obj.playersScore.player1.tiePoint = 0
                    obj.playersScore.player2.tiePoint = 0
                    obj.playersScore.player1.game = 0
                    obj.playersScore.player2.game = 0
                    obj.tieBreak = false
                    obj.playersScore.player2.set = player2?.set + 1
                    obj.startServing = match?.player1?._id === match?.tieBreakServing ? match?.player2?._id : match?.player1?._id
                    obj.servingSide = match?.tieBreakServing !== match?.startServing ? servingSide === 'top' ? 'bottom' : 'top' : match?.servingSide
                    setTie1([0, 1])
                    setTie2([0, 1])

                    if (tournament?.score === 3 && obj?.playersScore?.player2?.set === 2 || tournament?.score === 5 && obj?.playersScore?.player2?.set === 3) {
                        obj.winner = match?.player2?._id
                    }
                }
                else {
                    adminConsole('totalTieBreak', totalTieBreak)
                    if (totalTieBreak && totalTieBreak % 6 === 0) {
                        obj.servingSide = servingSide === 'top' ? 'bottom' : 'top'
                    }
                    else if (totalTieBreak && totalTieBreak % 2 !== 0) {
                        obj.servingSide = servingSide === 'top' ? 'bottom' : 'top'
                        obj.startServing = match?.player1?._id === match?.startServing ? match?.player2?._id : match?.player1?._id
                    }
                }
            }

            adminConsole('obj****', obj)
        }
        else {
            arr = [{ key: value, value: value }]

            if (value === 0 || value === 15) {
                newVal = value + 15
                arr.push({ key: newVal, value: newVal })
            }
            else if (value === 30) {
                newVal = value + 10
                arr.push({ key: newVal, value: newVal })
            }
            else if (value === 40) {
                arr.push({ key: 0, value: 0 })
            }
            else {
                arr = [{ key: 'ADV', value }, { key: 0, value: 0 }]
            }

            if (key === 'player1') {
                obj.playersScore = {
                    player1: {
                        point: value,
                        tiePoint: 0,
                        game: player1?.game,
                        set: player1?.set
                    },
                    player2: {
                        point: player2?.point,
                        tiePoint: 0,
                        game: player2?.game,
                        set: player2?.set
                    }
                }

                obj.lastPoint = match?.playersScore

                if (value === 40 && player2?.point === 40 || value === 40 && player2?.point === 50 || value === 50 && player2?.point === 50) {
                    arr = [{ key: 40, value: 40 }, { key: 'ADV', value: 50 }]
                    setPoint1(arr)
                    setPoint2(arr)
                    obj.playersScore.player1.point = 40
                    obj.playersScore.player2.point = 40
                }
                else if (value === 0 && player1?.point !== 0) {
                    arr = [{ key: 0, value: 0 }, { key: 15, value: 15 }]
                    setPoint1(arr)
                    setPoint2(arr)
                    obj.playersScore.player1.point = 0
                    obj.playersScore.player2.point = 0
                    obj.playersScore.player1.game = player1?.game + 1
                    diff = obj?.playersScore?.player1?.game - player2?.game
                    obj.startServing = match?.player1?._id === match?.startServing ? match?.player2?._id : match?.player1?._id
                    if (obj?.playersScore?.player1?.game === 6 && diff >= 2 || obj?.playersScore?.player1?.game === 7 && diff >= 2) {
                        obj?.gamePoint?.push([player1?.game + 1, player2?.game])
                        obj.playersScore.player1.game = 0
                        obj.playersScore.player2.game = 0
                        obj.playersScore.player1.set = player1?.set + 1

                        adminConsole('obj?.playersScore?.player1?.set + player2?.set === tournament?.score')
                        adminConsole('obj?.playersScore?.player1?.set', obj?.playersScore?.player1?.set)
                        adminConsole('player2?.set', player2?.set)
                        adminConsole('tournament?.score', tournament?.score)

                        if (tournament?.score === 3 && obj?.playersScore?.player1?.set === 2 || tournament?.score === 5 && obj?.playersScore?.player1?.set === 3) {
                            obj.winner = match?.player1?._id
                        }
                    }
                    else if (obj?.playersScore?.player1?.game === 6 && player2?.game === 6) {
                        obj.playersScore.player1.point = 0
                        obj.playersScore.player2.point = 0
                        obj.tieBreak = true
                        obj.tieBreakServing = obj?.startServing
                    }

                    totalGames = obj?.playersScore?.player1?.game + player2?.game

                    adminConsole('totalGames if', totalGames)
                    if (totalGames % 2 === 0) {
                        adminConsole('totalGames if', totalGames)
                        obj.servingSide = servingSide === 'top' ? 'bottom' : 'top'
                    }
                    adminConsole('obj', obj)
                }
                else {
                    setPoint1(arr)
                }
            }
            else {
                obj.playersScore = {
                    player1: {
                        point: player1?.point,
                        tiePoint: 0,
                        game: player1?.game,
                        set: player1?.set
                    },
                    player2: {
                        point: value,
                        tiePoint: 0,
                        game: player2?.game,
                        set: player2?.set
                    }
                }

                obj.lastPoint = match?.playersScore

                if (value === 40 && player1?.point === 40 || value === 50 && player1?.point === 50) {
                    arr = [{ key: 40, value: 40 }, { key: 'ADV', value: 50 }]
                    setPoint1(arr)
                    setPoint2(arr)
                    obj.playersScore.player1.point = 40
                    obj.playersScore.player2.point = 40
                }
                else if (value === 0 && player2?.point !== 0) {
                    arr = [{ key: 0, value: 0 }, { key: 15, value: 15 }]
                    setPoint1(arr)
                    setPoint2(arr)
                    obj.playersScore.player1.point = 0
                    obj.playersScore.player2.point = 0
                    obj.playersScore.player2.game = player2?.game + 1
                    obj.startServing = match?.player1?._id === match?.startServing ? match?.player2?._id : match?.player1?._id
                    diff = obj?.playersScore?.player2?.game - player1?.game
                    if (obj?.playersScore?.player2?.game === 6 && diff >= 2 || obj?.playersScore?.player2?.game === 7 && diff >= 2) {
                        obj?.gamePoint?.push([player1?.game, player2?.game + 1])
                        obj.playersScore.player1.game = 0
                        obj.playersScore.player2.game = 0
                        obj.playersScore.player2.set = player2?.set + 1

                        if (tournament?.score === 3 && obj?.playersScore?.player2?.set === 2 || tournament?.score === 5 && obj?.playersScore?.player2?.set === 3) {
                            obj.winner = match?.player2?._id
                        }
                    }
                    else if (obj?.playersScore?.player2?.game === 6 && player1?.game === 6) {
                        obj.playersScore.player1.point = 0
                        obj.playersScore.player2.point = 0
                        obj.tieBreak = true
                        obj.tieBreakServing = obj?.startServing
                    }

                    totalGames = obj?.playersScore?.player2?.game + player1?.game
                    adminConsole('totalGames', totalGames)
                    if (totalGames % 2 === 0) {
                        adminConsole('totalGames else', totalGames)
                        obj.servingSide = servingSide === 'top' ? 'bottom' : 'top'
                    }
                    adminConsole('obj', obj)
                }
                else {
                    setPoint2(arr)
                }
            }
        }
        adminConsole('obj****', obj)
        updateScore(obj)
    }

    const initScore = () => {
        let obj = {
            _id: match?._id,
            playersScore: {
                player1: {
                    point: 0,
                    tiePoint: 0,
                    game: 0,
                    set: 0
                },
                player2: {
                    point: 0,
                    tiePoint: 0,
                    game: 0,
                    set: 0
                }
            }
        }
        updateScore(obj)
    }

    const updateScore = (obj) => {
        matchActions?.startMatch({ ...match, ...obj })
        updateInSections()
        axios.post(POST.UPDATE_SCORE, obj)
            .then((res) => {
                const { data } = res
                if (data?.success) {
                    if (data?.match?.winner) {
                        successNotification('Match Ended!')

                        setTimeout(() => {
                            history?.push(allPaths?.HOME)
                        }, 1000)
                    }
                    matchActions?.startMatch(data?.match)
                }
                else {
                    errorMessage()
                }
            })
    }

    const stopMatch = () => {
        const { player1, player2 } = match
        let winner
        setLoading(true)

        if (stopReason !== 'ref') {
            winner = stopReason === player1?._id ? player2?._id : player1?._id

            let obj = {
                _id: match?._id,
                winner
            }

            updateScore(obj)

            setTimeout(() => {
                setLoading(true)
                history?.push(allPaths.HOME)
            }, 1000)
        }
        else {
            history?.push(allPaths.HOME)
            setLoading(false)
        }
    }

    const checkExtra = () => {
        let { strokes, lastStroke } = match

        return strokes?.length ? strokes?.slice(-1,)[0] === lastStroke?._id ? true : false : false
    }

    const addExtra = (v) => {

        if (checkExtra()) {
            let { lastStroke } = match
            lastStroke.extra = []
            if (lastStroke?.extra?.indexOf(v) === -1) {
                setSpin({ [v]: true })
                lastStroke?.extra?.push(v)

                let obj = {
                    match,
                    userId: user?._id,
                    stroke: lastStroke,
                    extra: v
                }

                let btn

                if (v === 'r') {
                    if (activeId) {
                        let btn = document.getElementById(activeId)
                        btn.className = btn.className.replace('active-btn', '')
                    }
                }

                axios.post(POST.ADD_EXTRA, obj)
                    .then((res) => {
                        const { data } = res
                        setSpin({})
                        if (data?.success) {
                            matchActions?.startMatch(data?.match)
                        }
                        else {
                            errorMessage()
                        }
                    })
            }
            else {
                warningMessage('Extra Button Already existed for this stroke!')
            }
        }
        else {
            errorMessage('Please, first click on Section!')
        }
    }

    const undoStep = () => {
        const { lastPoint, lastServingSide, lastServing, lastTieBreak, gamePoint } = match
        let obj = {
            _id: match?._id,
            lastPoint: {},
            playersScore: match?.playersScore,
            servingSide: lastServingSide,
            startServing: lastServing,
            gamePoint
        }

        obj.playersScore = lastPoint
        obj.tieBreak = lastTieBreak

        let totalSets = obj?.playersScore?.player1?.set + obj?.playersScore?.player2?.set
        obj.gamePoint = gamePoint?.slice(0, totalSets)

        updateScore(obj)
        updateOptions('player1', lastPoint?.player1?.point)
        updateOptions('player2', lastPoint?.player2?.point)
        updateTieBreak('player1', lastPoint?.player1?.tiePoint)
        updateTieBreak('player2', lastPoint?.player2?.tiePoint)

        if (activeId) {
            let btn = document.getElementById(activeId)
            btn.className = btn.className.replace('active-btn', '')
        }
    }

    const updateTieBreak = (key, value) => {
        let arr = [value, value + 1]

        key === 'player1' ? setTie1(arr) : setTie2(arr)
    }

    const updateOptions = (key, value) => {
        let newVal
        let arr = [{ key: value, value: value }]

        if (value === 0 || value === 15) {
            newVal = value + 15
            arr.push({ key: newVal, value: newVal })
        }
        else if (value === 30) {
            newVal = value + 10
            arr.push({ key: newVal, value: newVal })
        }
        else if (value === 40) {
            if (match?.playersScore?.player1?.point >= 40 && match?.playersScore?.player2?.point >= 40) {
                arr.push({ key: 'ADV', value: 50 })
            }
            else {
                arr.push({ key: 0, value: 0 })
            }
        }
        else {
            arr = [{ key: 'ADV', value }, { key: 0, value: 0 }]
        }

        key === 'player1' ? setPoint1(arr) : setPoint2(arr)
    }

    const stopModal = () => {
        const { player1, player2 } = match
        return (
            <Modal
                title='Reason'
                visible={showModal}
                footer={[
                    <Button
                        key='back'
                        disabled={loading}
                        onClick={() => (setShowModal(false), setStopReason('ref'))}
                        style={borderRadius}
                    >
                        Cancel
                    </Button>,
                    <Button
                        key='submit'
                        type='primary'
                        loading={loading}
                        onClick={stopMatch}
                        style={borderRadius}
                    >
                        Stop
                    </Button>
                ]}
            >
                <Radio.Group onChange={(e) => setStopReason(e?.target?.value)} value={stopReason}>
                    <Space direction='vertical'>
                        <Radio value={'ref'}>Ref</Radio>
                        <Radio value={player1?._id}>{player1?.name}</Radio>
                        <Radio value={player2?._id}>{player2?.name}</Radio>
                    </Space>
                </Radio.Group>
            </Modal>
        )
    }

    if (match?.scoring === 'points') {
        return (
            <div className='home-main' style={{ alignItems: 'center' }}>
                <div className='card1 flex-column' style={{ marginTop: 100 }}>
                    <div className='flex-row' style={{ justifyContent: 'flex-end', marginBottom: -5 }}>
                        <Button
                            style={{ width: 100, borderRadius: 4, marginTop: 10, marginRight: 10 }}
                            onClick={() => setShowModal(true)}
                        >Stop Match</Button>
                        <Button
                            style={{ width: 100, borderRadius: 4, marginTop: 10 }}
                            disabled={match?.lastPoint && match?.playersScore || match?.lastPoint?.point === 0 && match?.playersScore ? false : true}
                            onClick={undoStep}
                        >Undo</Button>
                    </div>
                    <table className='table table-bordered' style={{ marginTop: 10 }}>
                        <thead>
                            <tr>
                                <th>PLAYER</th>
                                <th>{match?.player1?.name}</th>
                                <th>{match?.player2?.name}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {!match?.tieBreak ? <tr>
                                <td>POINT</td>
                                <td>
                                    <select
                                        className='form-control'
                                        value={match?.playersScore?.player1?.point}
                                        style={{ width: 80 }}
                                        onChange={(e) => updatePoints('player1', e?.target?.value)}
                                    >
                                        {point1?.map((v, i) => <option value={v?.value} key={i}>{v?.key}</option>)}
                                    </select>
                                </td>
                                <td>
                                    <select
                                        className='form-control'
                                        value={match?.playersScore?.player2?.point}
                                        style={{ width: 80 }}
                                        onChange={(e) => updatePoints('player2', e?.target?.value)}
                                    >
                                        {point2?.map((v, i) => <option value={v?.value} key={i}>{v?.key}</option>)}
                                    </select>
                                </td>
                            </tr> : <tr>
                                <td>TIE BREAK</td>
                                <td>
                                    <select
                                        className='form-control'
                                        value={match?.playersScore?.player1?.tiePoint || 0}
                                        style={{ width: 80 }}
                                        onChange={(e) => updatePoints('player1', e?.target?.value)}
                                    >
                                        {tie1?.map((v, i) => <option value={v} key={i}>{v}</option>)}
                                    </select>
                                </td>
                                <td>
                                    <select
                                        className='form-control'
                                        value={match?.playersScore?.player2?.tiePoint || 0}
                                        style={{ width: 80 }}
                                        onChange={(e) => updatePoints('player2', e?.target?.value)}
                                    >
                                        {tie2?.map((v, i) => <option value={v} key={i}>{v}</option>)}
                                    </select>
                                </td>
                            </tr>}
                            <tr>
                                <td>GAME</td>
                                <td>{match?.playersScore?.player1?.game || 0}</td>
                                <td>{match?.playersScore?.player2?.game || 0}</td>
                            </tr>
                            <tr>
                                <td>SET</td>
                                <td>{match?.playersScore?.player1?.set || 0}</td>
                                <td>{match?.playersScore?.player2?.set || 0}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                {stopModal()}
            </div>
        )
    }

    return (
        <div className='home-main'>
            <div className='flex-row'>
                <div className='card flex-column' style={{ minWidth: '50%', height: window.innerHeight - 70 }}>
                    <div className='flex-row' style={{ height: 50 }}>
                        {buttonComp(tennisCorners.g)}
                        <div className='flex-row mid-width'>
                            <div className='flex-column flex-one'>
                                {buttonComp(tennisCorners?.h1)}
                                <div className='flex-rows'>
                                    {renderList(tennisCorners.h1Child)}
                                </div>
                            </div>
                            <div className='flex-column flex-one'>
                                {buttonComp(tennisCorners?.h5)}
                                <div className='flex-rows'>
                                    {renderList(tennisCorners.h5Child)}
                                </div>
                            </div>
                        </div>
                        {buttonComp(tennisCorners.i)}
                    </div>
                    <div className='flex-row mid-height'>
                        <div className='flex-column corners mid-height'>
                            <div className='flex-column flex-one'>
                                <div className='flex-row flex-one'>
                                    {buttonComp(tennisCorners.e1)}
                                    <div className='flex-column child-btn'>
                                        {renderList(tennisCorners.e1Child)}
                                    </div>
                                </div>
                                <div className='flex-row flex-one'>
                                    {buttonComp(tennisCorners.e5)}
                                    <div className='flex-column child-btn'>
                                        {renderList(tennisCorners.e5Child)}
                                    </div>
                                </div>
                            </div>
                            <div className='flex-column flex-one'>
                                <div className='flex-row flex-one'>
                                    {buttonComp(tennisCorners.j5)}
                                    <div className='flex-column child-btn'>
                                        {renderList(tennisCorners.j5Child)}
                                    </div>
                                </div>
                                <div className='flex-row flex-one'>
                                    {buttonComp(tennisCorners.j1)}
                                    <div className='flex-column child-btn'>
                                        {renderList(tennisCorners.j1Child)}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='flex-column mid-width section-in'>
                            <div className='flex-column flex-one'>
                                <div className='flex-row flex-one'>
                                    {renderList(midTop)}
                                </div>
                                <div className='flex-row flex-two'>
                                    {renderList(midTop2)}
                                </div>
                                <div className='flex-row flex-two'>
                                    {renderList(midTop3)}
                                </div>
                                <div className='flex-column flex-four section-in' style={{ position: 'relative' }}>
                                    <div style={{ flex: 10 }} className='flex-row'>
                                        {renderList(midTop4Child)}
                                    </div>
                                    <div style={{ flex: 44 }} className='flex-row'>
                                        {renderMainList(midTop4)}
                                    </div>
                                    <div style={{ flex: 10 }} className='flex-row'>
                                        {renderList(midTop4Child2)}
                                    </div>
                                </div>
                            </div>
                            <div className='flex-row'>
                                {renderList(oSection)}
                            </div>
                            <div className='flex-column flex-one'>
                                <div className='flex-column flex-four section-in'>
                                    <div style={{ flex: 10 }} className='flex-row'>
                                        {renderList(midBottom4Child2)}
                                    </div>
                                    <div style={{ flex: 44 }} className='flex-row'>
                                        {renderList(midBottom4)}
                                    </div>
                                    <div style={{ flex: 10 }} className='flex-row'>
                                        {renderList(midBottom4Child)}
                                    </div>
                                </div>
                                <div className='flex-row flex-two'>
                                    {renderList(midBottom3)}
                                </div>
                                <div className='flex-row flex-two'>
                                    {renderList(midBottom2)}
                                </div>
                                <div className='flex-row flex-one'>
                                    {renderList(midBottom)}
                                </div>
                            </div>
                        </div>
                        <div className='flex-column corners mid-height' style={{ position: 'relative' }}>
                            <div className='flex-column flex-one'>
                                <div className='flex-row flex-one'>
                                    <div className='flex-column child-btn'>
                                        {renderList(tennisCorners.f1Child)}
                                    </div>
                                    {buttonComp(tennisCorners.f1)}
                                </div>
                                <div className='flex-row flex-one'>
                                    <div className='flex-column child-btn'>
                                        {renderList(tennisCorners.f5Child)}
                                    </div>
                                    {buttonComp(tennisCorners.f5)}
                                </div>
                            </div>
                            <div className='flex-column flex-one'>
                                <div className='flex-row flex-one'>
                                    <div className='flex-column child-btn'>
                                        {renderList(tennisCorners.k5Child)}
                                    </div>
                                    {buttonComp(tennisCorners.k5)}
                                </div>
                                <div className='flex-row flex-one'>
                                    <div className='flex-column child-btn'>
                                        {renderList(tennisCorners.k1Child)}
                                    </div>
                                    {buttonComp(tennisCorners.k1)}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='flex-row' style={{ height: 50 }}>
                        {buttonComp(tennisCorners.l)}
                        <div className='flex-row mid-width'>
                            <div className='flex-column flex-one'>
                                <div className='flex-rows'>
                                    {renderList(tennisCorners.m5Child)}
                                </div>
                                {buttonComp(tennisCorners?.m5)}
                            </div>
                            <div className='flex-column flex-one'>
                                <div className='flex-rows'>
                                    {renderList(tennisCorners.m1Child)}
                                </div>
                                {buttonComp(tennisCorners?.m1)}
                            </div>
                        </div>
                        {buttonComp(tennisCorners.n)}
                    </div>
                </div>
                <div style={{ flex: 1 }}>
                    <div className='match-score flex-column'>
                        <div className='flex-row' style={{ justifyContent: 'flex-end', marginBottom: -5 }}>
                            <Button
                                style={{ width: 100, borderRadius: 4, marginTop: 10 }}
                                disabled={match?.lastPoint ? false : true}
                                onClick={undoStep}
                            >Undo</Button>
                        </div>
                        <table className='table table-bordered' style={{ marginTop: 10 }}>
                            <thead>
                                <tr>
                                    <th>PLAYER</th>
                                    <th>{match?.player1?.name}</th>
                                    <th>{match?.player2?.name}</th>
                                </tr>
                            </thead>
                            <tbody>
                                {!match?.tieBreak ? <tr>
                                    <td>POINT</td>
                                    <td>
                                        <select
                                            className='form-control'
                                            value={match?.playersScore?.player1?.point}
                                            style={{ width: 80 }}
                                            onChange={(e) => updatePoints('player1', e?.target?.value)}
                                        >
                                            {point1?.map((v, i) => <option value={v?.value} key={i}>{v?.key}</option>)}
                                        </select>
                                    </td>
                                    <td>
                                        <select
                                            className='form-control'
                                            value={match?.playersScore?.player2?.point}
                                            style={{ width: 80 }}
                                            onChange={(e) => updatePoints('player2', e?.target?.value)}
                                        >
                                            {point2?.map((v, i) => <option value={v?.value} key={i}>{v?.key}</option>)}
                                        </select>
                                    </td>
                                </tr> : <tr>
                                    <td>TIE BREAK</td>
                                    <td>
                                        <select
                                            className='form-control'
                                            value={match?.playersScore?.player1?.tiePoint || 0}
                                            style={{ width: 80 }}
                                            onChange={(e) => updatePoints('player1', e?.target?.value)}
                                        >
                                            {tie1?.map((v, i) => <option value={v} key={i}>{v}</option>)}
                                        </select>
                                    </td>
                                    <td>
                                        <select
                                            className='form-control'
                                            value={match?.playersScore?.player2?.tiePoint || 0}
                                            style={{ width: 80 }}
                                            onChange={(e) => updatePoints('player2', e?.target?.value)}
                                        >
                                            {tie2?.map((v, i) => <option value={v} key={i}>{v}</option>)}
                                        </select>
                                    </td>
                                </tr>}
                                <tr>
                                    <td>GAME</td>
                                    <td>{match?.playersScore?.player1?.game || 0}</td>
                                    <td>{match?.playersScore?.player2?.game || 0}</td>
                                </tr>
                                <tr>
                                    <td>SET</td>
                                    <td>{match?.playersScore?.player1?.set || 0}</td>
                                    <td>{match?.playersScore?.player2?.set || 0}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', position: 'fixed', top: '40%', height: '56%', right: 30 }}>
                        {extraButtons?.map((v, i) => {
                            return (
                                <div key={i}>
                                    <Button
                                        onClick={() => addExtra(v)}
                                    >{convertTitle(v)}</Button>
                                    {spin[v] ? <div className='demo-loading-container' style={{ marginTop: -25, marginLeft: 5 }}>
                                        <Spin />
                                    </div> : null}
                                </div>
                            )
                        })}
                    </div>
                    {match?.servingSide === 'top' ? <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', position: 'relative', height: '63%', width: 200 }}>
                        <Button type='primary' style={borderRadius}>{match?.player1?._id === match?.startServing ? match?.player1?.name : match?.player2?.name}&nbsp;(S)</Button>
                        <Button style={borderRadius} onClick={() => setShowModal(true)}>Stop Match</Button>
                        <Button style={borderRadius}>{match?.player1?._id === match?.startServing ? match?.player2?.name : match?.player1?.name}</Button>
                    </div> : <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', position: 'relative', height: '63%', width: 200 }}>
                        <Button style={borderRadius}>{match?.player1?._id === match?.startServing ? match?.player2?.name : match?.player1?.name}</Button>
                        <Button style={borderRadius} onClick={() => setShowModal(true)}>Stop Match</Button>
                        <Button type='primary' style={borderRadius}>{match?.player1?._id === match?.startServing ? match?.player1?.name : match?.player2?.name}&nbsp;(S)</Button>
                    </div>}
                </div>
            </div>
            {stopModal()}
        </div>
    )
}

export default MatchTool