import React, { useState, useEffect } from 'react'
import { Form, Button, Input, DatePicker, Select } from 'antd'
import axios from 'axios'
import { inputPlace, requiredMessage, convertTitle, successNotification, errorMessage } from '../../../utils/helpers'
import { POST } from '../../../utils/apis'

const { RangePicker } = DatePicker
const { Option } = Select

const tournamentType = ['atp250', 'atp500', 'atp1000', 'Grandslam', 'Challenger', 'itf', 'ATP cup']
const surfaceType = ['clay', 'hard', 'grass']
const scoreType = [5, 3]
const venueType = ['indoor', 'outdoor']

const AddTournament = (props) => {
    const { user, onCancel, edit } = props
    const [loading, setLoading] = useState(false)
    const [countries, setCountries] = useState([])

    useEffect(() => {
        axios.get('https://raw.githubusercontent.com/mansoor876/Countries/main/countries.json')
            .then((req) => {
                setCountries(Object.keys(req?.data))
            })
    }, [])

    const onFinish = (values) => {
        setLoading(true)
        values.userId = user?._id
        edit?._id && (values._id = edit?._id)
        axios.post(POST.ADD_TOURNAMENT, values)
            .then((res) => {
                const { data } = res
                setLoading(false)
                if (data.success) {
                    successNotification('Successfully Created!')
                    return setTimeout(() => {
                        onCancel()
                    }, 300)
                }
                errorMessage(data.message)
            })
            .catch((e) => {
                setLoading(false)
                errorMessage()
            })
    }

    return (
        <div style={{ paddingBottom: 40 }}>
            <div className='card-form'>
                <div className='card2'>
                    <p className='heading head-center'>{edit ? 'Update' : 'Create'} Tournament</p>
                    <Form
                        name='form'
                        onFinish={onFinish}
                        layout={'vertical'}
                        requiredMark={false}
                    >
                        <Form.Item
                            name='name'
                            label='Tournament Name'
                            initialValue={edit?.name}
                            hasFeedback
                            rules={[
                                {
                                    required: true,
                                    message: requiredMessage('Tournament Name')
                                },
                                {
                                    max: 50
                                }
                            ]}
                        >
                            <Input
                                className='form-input'
                                placeholder={inputPlace('Tournament Name')}
                            />
                        </Form.Item>
                        <Form.Item
                            name='date'
                            label='Date'
                            className='form-layout left-layout'
                            initialValue={edit?.date}
                            hasFeedback
                            rules={[
                                {
                                    required: true,
                                    message: requiredMessage('Start And End Date')
                                }
                            ]}
                        >
                            <RangePicker className='form-input' style={{ width: '100%' }} />
                        </Form.Item>
                        <div className='flex-row flex-mobile'>
                            <Form.Item
                                name='country'
                                label='Country'
                                className='form-layout left-layout'
                                initialValue={edit?.country}
                                hasFeedback
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please Select Country'
                                    }
                                ]}
                            >
                                <Select
                                    showSearch
                                    showArrow
                                    className='form-input'
                                    placeholder='Select Country'
                                    optionFilterProp='children'
                                    filterOption={(input, option) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                >
                                    {countries?.map((v, i) => <Option value={v} key={i}>{convertTitle(v)}</Option>)}
                                </Select>
                            </Form.Item>
                            <Form.Item
                                name='type'
                                label='Tournamnet Type'
                                initialValue={edit?.type}
                                className='form-layout right-layout'
                                hasFeedback
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please Tournamnet Type'
                                    }
                                ]}
                            >
                                <Select
                                    showSearch
                                    showArrow
                                    className='form-input'
                                    placeholder='Tournamnet Type'
                                    optionFilterProp='children'
                                    filterOption={(input, option) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                >
                                    {tournamentType?.map((v, i) => <Option value={v} key={i}>{convertTitle(v)}</Option>)}
                                    <Option value={'WTA'}>WTA</Option>
                                </Select>
                            </Form.Item>
                        </div>
                        <div className='flex-row flex-mobile'>
                            <Form.Item
                                name='city'
                                label='City'
                                className='form-layout left-layout'
                                initialValue={edit?.city}
                                hasFeedback
                                rules={[
                                    {
                                        required: true,
                                        message: requiredMessage('City')
                                    },
                                    {
                                        max: 50
                                    }
                                ]}
                            >
                                <Input
                                    className='form-input'
                                    placeholder={inputPlace('City')}
                                />
                            </Form.Item>
                            <Form.Item
                                name='venue'
                                label='Venue'
                                className='form-layout right-layout'
                                initialValue={edit?.venue}
                                hasFeedback
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please Select Venue'
                                    }
                                ]}
                            >
                                <Select
                                    showSearch
                                    showArrow
                                    allowClear
                                    className='form-input'
                                    placeholder='Select Venue'
                                    optionFilterProp='children'
                                    filterOption={(input, option) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                >
                                    {venueType?.map((v, i) => <Option value={v} key={i}>{convertTitle(v)}</Option>)}
                                </Select>
                            </Form.Item>
                        </div>
                        <div className='flex-row flex-mobile'>
                            <Form.Item
                                name='surface'
                                label='Surface'
                                initialValue={edit?.surface}
                                className='form-layout left-layout'
                                hasFeedback
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please Select Surface'
                                    }
                                ]}
                            >
                                <Select
                                    showSearch
                                    showArrow
                                    className='form-input'
                                    placeholder='Select Surface'
                                    optionFilterProp='children'
                                    filterOption={(input, option) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                >
                                    {surfaceType?.map((v, i) => <Option value={v} key={i}>{convertTitle(v)}</Option>)}
                                </Select>
                            </Form.Item>
                            <Form.Item
                                name='score'
                                label='Score'
                                initialValue={edit?.score}
                                className='form-layout right-layout'
                                hasFeedback
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please Select Score'
                                    }
                                ]}
                            >
                                <Select
                                    showSearch
                                    showArrow
                                    className='form-input'
                                    placeholder='Select Score'
                                    optionFilterProp='children'
                                    filterOption={(input, option) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                >
                                    {scoreType?.map((v, i) => <Option value={v} key={i}>Best of {v}</Option>)}
                                </Select>
                            </Form.Item>
                        </div>
                        <Form.Item>
                            <Button
                                type='primary'
                                className='form-button'
                                block
                                htmlType='submit'
                                loading={loading}
                            >
                                {edit ? 'Update' : 'Create'}
                            </Button>
                            <Button
                                style={{ borderRadius: 4, marginTop: 6 }}
                                block
                                onClick={onCancel}
                            >
                                Cancel
                            </Button>
                        </Form.Item>
                    </Form>
                </div>
            </div>
        </div>
    )
}

export default AddTournament