import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { Form, Input, Button } from 'antd'
import { requiredMessage, inputPlace, successNotification, errorMessage } from '../../utils/helpers'
import { AUTH } from '../../utils/apis'
import { SocialLoginButtons } from '../../Components'
import { loginUser, removeUser } from '../../Redux/actions/authActions'
import axios from 'axios'

const Login = (props) => {
    const dispatch = useDispatch()
    const [loading, setLoading] = useState(false)

    const onFinish = (values) => {
        const { history } = props
        setLoading(true)
        axios.post(AUTH.LOGIN, values)
            .then((res) => {
                const { data } = res
                setLoading(false)
                if (data.success) {
                    successNotification('Successfully Logged In!')
                    dispatch(loginUser(data.user))
                    return setTimeout(() => {
                        history.push('/')
                    }, 300)
                }
                errorMessage(data.message)
            })
            .catch((e) => {
                setLoading(false)
                errorMessage()
            })
    }

    useEffect(() => {
        dispatch(removeUser())
    }, [])

    return (
        <div className='card-parent'>
            <div className='card1'>
                <p className='heading head-center'>Login And Play Tennis</p>
                <Form
                    name='form'
                    onFinish={onFinish}
                    layout={'vertical'}
                    requiredMark={false}
                >
                    <Form.Item
                        name='email'
                        label='Email'
                        hasFeedback
                        rules={[
                            {
                                required: true,
                                message: requiredMessage('Email')
                            },
                            {
                                type: 'email'
                            }
                        ]}
                    >
                        <Input
                            className='form-input'
                            placeholder={inputPlace('Email')}
                        />
                    </Form.Item>
                    <Form.Item
                        name='password'
                        label='Password'
                        hasFeedback
                        rules={[
                            {
                                required: true,
                                message: requiredMessage('Password')
                            },
                            {
                                min: 6
                            }
                        ]}
                    >
                        <Input.Password
                            className='form-input'
                            placeholder={inputPlace('Password')}
                        />
                    </Form.Item>
                    <Form.Item>
                        <Button
                            type='primary'
                            className='form-button'
                            block
                            htmlType='submit'
                            loading={loading}
                        >
                            Submit
                        </Button>
                    </Form.Item>
                </Form>
                <SocialLoginButtons {...props} loginUser={(e) => dispatch(loginUser(e))} />
                <p>Don't have an account? <Link to='/register'>Sign up</Link>
                    <br />
                    <Link to='/forgot'>Forgot Password</Link>
                </p>
            </div>
        </div>
    )
}

export default Login