import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { Header } from '../'
import { useSelector } from 'react-redux'
import { Route, Redirect } from 'react-router-dom'
import { loginUser, removeUser, addMatchesList, addUsersList, addPlayersList, addTournamentsList } from '../../Redux/actions/authActions'
import { startMatch, stopMatch, setFault } from '../../Redux/actions/matchActions'
import { GET } from '../../utils/apis'
import axios from 'axios'


const MenuLayout = ({ component: Component, ...rest }) => {
    const dispatch = useDispatch()
    const user = useSelector(state => state.authReducer.user)
    const authActions = {
        loginUser: (e) => dispatch(loginUser(e)),
        removeUser: () => dispatch(removeUser()),
        addMatchesList: (e) => dispatch(addMatchesList(e)),
        addUsersList: (e) => dispatch(addUsersList(e)),
        addPlayersList: (e) => dispatch(addPlayersList(e)),
        addTournamentsList: (e) => dispatch(addTournamentsList(e))
    }

    const matchActions = {
        startMatch: (e) => dispatch(startMatch(e)),
        setFault: (e) => dispatch(setFault(e)),
        stopMatch: () => dispatch(stopMatch())
    }

    useEffect(() => {
        if (user) {
            axios.get(`${GET.USER_BY_ID}/${user._id}`)
                .then((res) => {
                    const { data } = res
                    if (data.success) {
                        dispatch(loginUser(data.user))
                    }
                    else {
                        dispatch(removeUser())
                    }
                })
                .catch((e) => {
                    dispatch(removeUser())
                })
        }
    }, [])

    if (!user) {
        return (
            <Redirect to='/login' />
        )
    }

    return (
        <Route
            {...rest}
            render={props => <AddMenu {...props} component={Component} user={user} authActions={authActions} matchActions={matchActions} />}
        />
    )
}

const AddMenu = ({ component: Component, ...props }) => {

    return (
        <div style={{ height: '100vh', overflow: 'hidden' }}>
            <Header {...props} />
            <Component {...props} />
        </div>
    )
}

export { MenuLayout, AddMenu }