import { HomeOutlined, SettingOutlined, DollarOutlined, QuestionOutlined } from '@ant-design/icons'
import allPaths from '../Config/paths'

const bgColor = '#0adc00'
const googleClientId = '202056254581-atll6adadjh6hpum280usr41a6o7uvhc.apps.googleusercontent.com'
const facebookId = '695047224503624'

const drawerRoutes = [
    {
        title: 'Home',
        route: allPaths.HOME,
        icon: <HomeOutlined />
    },
    {
        title: 'Settings',
        route: allPaths.SETTINGS,
        icon: <SettingOutlined />
    },
    {
        title: 'Transfer',
        route: allPaths.TRANSFER,
        icon: <DollarOutlined />
    },
    {
        title: 'Help',
        route: allPaths.HELP,
        icon: <QuestionOutlined />
    }
]

const tennisTop = [
    {
        name: 'g',
        classNames: 'btn-table btn-corners'
    },
    {
        name: 'h1',
        classNames: 'btn-table mid-btn'
    },
    {
        name: 'h5',
        classNames: 'btn-table mid-btn'
    },
    {
        name: 'i',
        classNames: 'btn-table btn-corners'
    }
]

const tennisCorners = {
    g: {
        name: 'g',
        classNames: 'btn-table btn-corners'
    },
    i: {
        name: 'i',
        classNames: 'btn-table btn-corners'
    },
    l: {
        name: 'l',
        classNames: 'btn-table btn-corners'
    },
    n: {
        name: 'n',
        classNames: 'btn-table btn-corners'
    },
    h1: {
        name: 'h1',
        classNames: 'btn-table corner-childs'
    },
    h1Child: [
        {
            name: 'h2',
            classNames: 'btn-table corner-childs child-width'
        },
        {
            name: 'h3',
            classNames: 'btn-table corner-childs child-width'
        },
        {
            name: 'h4',
            classNames: 'btn-table corner-childs child-width'
        }
    ],
    h5: {
        name: 'h5',
        classNames: 'btn-table corner-childs'
    },
    h5Child: [
        {
            name: 'h8',
            classNames: 'btn-table corner-childs child-width'
        },
        {
            name: 'h7',
            classNames: 'btn-table corner-childs child-width'
        },
        {
            name: 'h6',
            classNames: 'btn-table corner-childs child-width'
        }
    ],
    m5: {
        name: 'm5',
        classNames: 'btn-table corner-childs'
    },
    m5Child: [
        {
            name: 'm6',
            classNames: 'btn-table corner-childs child-width'
        },
        {
            name: 'm7',
            classNames: 'btn-table corner-childs child-width'
        },
        {
            name: 'm8',
            classNames: 'btn-table corner-childs child-width'
        }
    ],
    m1: {
        name: 'm1',
        classNames: 'btn-table corner-childs'
    },
    m1Child: [
        {
            name: 'm4',
            classNames: 'btn-table corner-childs child-width'
        },
        {
            name: 'm3',
            classNames: 'btn-table corner-childs child-width'
        },
        {
            name: 'm2',
            classNames: 'btn-table corner-childs child-width'
        }
    ],
    e1: {
        name: 'e1',
        classNames: 'btn-table corner-btn mid-height corner-width corner-childs'
    },
    e1Child: [
        {
            name: 'e2',
            classNames: 'btn-table corner-btn'
        },
        {
            name: 'e3',
            classNames: 'btn-table corner-btn'
        },
        {
            name: 'e4',
            classNames: 'btn-table corner-btn'
        }
    ],
    e5: {
        name: 'e5',
        classNames: 'btn-table corner-btn mid-height corner-width corner-childs'
    },
    e5Child: [
        {
            name: 'e6',
            classNames: 'btn-table corner-btn'
        },
        {
            name: 'e7',
            classNames: 'btn-table corner-btn'
        }
    ],
    j1: {
        name: 'j1',
        classNames: 'btn-table corner-btn mid-height corner-width corner-childs'
    },
    j1Child: [
        {
            name: 'j4',
            classNames: 'btn-table corner-btn'
        },
        {
            name: 'j3',
            classNames: 'btn-table corner-btn'
        },
        {
            name: 'j2',
            classNames: 'btn-table corner-btn'
        }
    ],
    j5: {
        name: 'j5',
        classNames: 'btn-table corner-btn mid-height corner-width corner-childs'
    },
    j5Child: [
        {
            name: 'j7',
            classNames: 'btn-table corner-btn'
        },
        {
            name: 'j6',
            classNames: 'btn-table corner-btn'
        }
    ],
    f1: {
        name: 'f1',
        classNames: 'btn-table corner-btn mid-height corner-width corner-childs'
    },
    f1Child: [
        {
            name: 'f2',
            classNames: 'btn-table corner-btn'
        },
        {
            name: 'f3',
            classNames: 'btn-table corner-btn'
        },
        {
            name: 'f4',
            classNames: 'btn-table corner-btn'
        }
    ],
    f5: {
        name: 'f5',
        classNames: 'btn-table corner-btn mid-height corner-width corner-childs'
    },
    f5Child: [
        {
            name: 'f6',
            classNames: 'btn-table corner-btn'
        },
        {
            name: 'f7',
            classNames: 'btn-table corner-btn'
        }
    ],
    k1: {
        name: 'k1',
        classNames: 'btn-table corner-btn mid-height corner-width corner-childs'
    },
    k1Child: [
        {
            name: 'k4',
            classNames: 'btn-table corner-btn'
        },
        {
            name: 'k3',
            classNames: 'btn-table corner-btn'
        },
        {
            name: 'k2',
            classNames: 'btn-table corner-btn'
        }
    ],
    k5: {
        name: 'k5',
        classNames: 'btn-table corner-btn mid-height corner-width corner-childs'
    },
    k5Child: [
        {
            name: 'k7',
            classNames: 'btn-table corner-btn'
        },
        {
            name: 'k6',
            classNames: 'btn-table corner-btn'
        }
    ]
}

const tennisRight = [
    {
        name: 'f1',
        classNames: 'btn-table corner-btn'
    },
    {
        name: 'f5',
        classNames: 'btn-table corner-btn'
    },
    {
        name: 'k5',
        classNames: 'btn-table corner-btn'
    },
    {
        name: 'k1',
        classNames: 'btn-table corner-btn'
    },
]

const tennisLeft = [
    {
        name: 'e1',
        classNames: 'btn-table corner-btn'
    },
    {
        name: 'e5',
        classNames: 'btn-table corner-btn'
    },
    {
        name: 'j5',
        classNames: 'btn-table corner-btn'
    },
    {
        name: 'j1',
        classNames: 'btn-table corner-btn'
    }
]

const midTop = [
    {
        name: 'a10',
        classNames: 'btn-table flex-one'
    },
    {
        name: 'a11',
        classNames: 'btn-table flex-one'
    },
    {
        name: 'a12',
        classNames: 'btn-table flex-one'
    },
    {
        name: 'b12',
        classNames: 'btn-table flex-one'
    },
    {
        name: 'b11',
        classNames: 'btn-table flex-one'
    },
    {
        name: 'b10',
        classNames: 'btn-table flex-one'
    }
]

const midTop2 = [
    {
        name: 'a7',
        classNames: 'btn-table flex-one mid-height'
    },
    {
        name: 'a8',
        classNames: 'btn-table flex-one mid-height'
    },
    {
        name: 'a9',
        classNames: 'btn-table flex-one mid-height'
    },
    {
        name: 'b9',
        classNames: 'btn-table flex-one mid-height'
    },
    {
        name: 'b8',
        classNames: 'btn-table flex-one mid-height'
    },
    {
        name: 'b7',
        classNames: 'btn-table flex-one mid-height'
    }
]

const midTop3 = [
    {
        name: 'a4',
        classNames: 'btn-table flex-one mid-height'
    },
    {
        name: 'a5',
        classNames: 'btn-table flex-one mid-height'
    },
    {
        name: 'a6',
        classNames: 'btn-table flex-one mid-height'
    },
    {
        name: 'b6',
        classNames: 'btn-table flex-one mid-height'
    },
    {
        name: 'b5',
        classNames: 'btn-table flex-one mid-height'
    },
    {
        name: 'b4',
        classNames: 'btn-table flex-one mid-height'
    }
]

const midTop4Child = [
    {
        name: 'a1a',
        classNames: 'btn-table flex-one mid-height'
    },
    {
        name: 'a2a',
        classNames: 'btn-table flex-one mid-height'
    },
    {
        name: 'a3a',
        classNames: 'btn-table flex-one mid-height right-border'
    },
    {
        name: 'b3a',
        classNames: 'btn-table flex-one mid-height left-border'
    },
    {
        name: 'b2a',
        classNames: 'btn-table flex-one mid-height'
    },
    {
        name: 'b1a',
        classNames: 'btn-table flex-one mid-height'
    }
]

const midTop4Child2 = [
    {
        name: 'a1b',
        classNames: 'btn-table flex-one mid-height'
    },
    {
        name: 'a2b',
        classNames: 'btn-table flex-one mid-height'
    },
    {
        name: 'a3b',
        classNames: 'btn-table flex-one mid-height right-border'
    },
    {
        name: 'b3b',
        classNames: 'btn-table flex-one mid-height left-border'
    },
    {
        name: 'b2b',
        classNames: 'btn-table flex-one mid-height'
    },
    {
        name: 'b1b',
        classNames: 'btn-table flex-one mid-height'
    }
]

const midTop4 = [
    {
        name: 'a1',
        classNames: 'btn-table flex-one mid-height'
    },
    {
        name: 'a2',
        classNames: 'btn-table flex-one mid-height'
    },
    {
        name: 'a3',
        classNames: 'btn-table flex-one mid-height right-border'
    },
    {
        name: 'b3',
        classNames: 'btn-table flex-one mid-height left-border'
    },
    {
        name: 'b2',
        classNames: 'btn-table flex-one mid-height'
    },
    {
        name: 'b1',
        classNames: 'btn-table flex-one mid-height'
    }
]

const midBottom = [
    {
        name: 'c10',
        classNames: 'btn-table flex-one mid-height'
    },
    {
        name: 'c11',
        classNames: 'btn-table flex-one mid-height'
    },
    {
        name: 'c12',
        classNames: 'btn-table flex-one mid-height'
    },
    {
        name: 'd12',
        classNames: 'btn-table flex-one mid-height'
    },
    {
        name: 'd11',
        classNames: 'btn-table flex-one mid-height'
    },
    {
        name: 'd10',
        classNames: 'btn-table flex-one mid-height'
    }
]

const midBottom2 = [
    {
        name: 'c7',
        classNames: 'btn-table flex-one mid-height'
    },
    {
        name: 'c8',
        classNames: 'btn-table flex-one mid-height'
    },
    {
        name: 'c9',
        classNames: 'btn-table flex-one mid-height'
    },
    {
        name: 'd9',
        classNames: 'btn-table flex-one mid-height'
    },
    {
        name: 'd8',
        classNames: 'btn-table flex-one mid-height'
    },
    {
        name: 'd7',
        classNames: 'btn-table flex-one mid-height'
    }
]

const midBottom3 = [
    {
        name: 'c4',
        classNames: 'btn-table flex-one mid-height'
    },
    {
        name: 'c5',
        classNames: 'btn-table flex-one mid-height'
    },
    {
        name: 'c6',
        classNames: 'btn-table flex-one mid-height'
    },
    {
        name: 'd6',
        classNames: 'btn-table flex-one mid-height'
    },
    {
        name: 'd5',
        classNames: 'btn-table flex-one mid-height'
    },
    {
        name: 'd4',
        classNames: 'btn-table flex-one mid-height'
    }
]

const midBottom4 = [
    {
        name: 'c1',
        classNames: 'btn-table flex-one'
    },
    {
        name: 'c2',
        classNames: 'btn-table flex-one'
    },
    {
        name: 'c3',
        classNames: 'btn-table flex-one right-border'
    },
    {
        name: 'd3',
        classNames: 'btn-table flex-one left-border'
    },
    {
        name: 'd2',
        classNames: 'btn-table flex-one'
    },
    {
        name: 'd1',
        classNames: 'btn-table flex-one'
    }
]

const midBottom4Child = [
    {
        name: 'c1a',
        classNames: 'btn-table flex-one'
    },
    {
        name: 'c2a',
        classNames: 'btn-table flex-one'
    },
    {
        name: 'c3a',
        classNames: 'btn-table flex-one right-border'
    },
    {
        name: 'd3a',
        classNames: 'btn-table flex-one left-border'
    },
    {
        name: 'd2a',
        classNames: 'btn-table flex-one'
    },
    {
        name: 'd1a',
        classNames: 'btn-table flex-one'
    }
]

const midBottom4Child2 = [
    {
        name: 'c1b',
        classNames: 'btn-table flex-one'
    },
    {
        name: 'c2b',
        classNames: 'btn-table flex-one'
    },
    {
        name: 'c3b',
        classNames: 'btn-table flex-one right-border'
    },
    {
        name: 'd3b',
        classNames: 'btn-table flex-one left-border'
    },
    {
        name: 'd2b',
        classNames: 'btn-table flex-one'
    },
    {
        name: 'd1b',
        classNames: 'btn-table flex-one'
    }
]

const oSection = [
    {
        name: 'o1',
        classNames: 'btn-table flex-one left-border'
    },
    {
        name: 'o2',
        classNames: 'btn-table flex-one'
    },
    {
        name: 'o3',
        classNames: 'btn-table flex-one'
    },
    {
        name: 'o4',
        classNames: 'btn-table flex-one'
    },
    {
        name: 'o5',
        classNames: 'btn-table flex-one'
    },
    {
        name: 'o6',
        classNames: 'btn-table flex-one right-border'
    }
]

const sectionReturn = (obj) => obj?.map(v => v?.name)

const topFirstServe = {
    right: ['d3b', 'd2b', 'd1b', 'd3', 'd2', 'd1', 'd3a', 'd2a', 'd1a'],
    left: ['c1b', 'c2b', 'c3b', 'c1', 'c2', 'c3', 'c1a', 'c2a', 'c3a']
}
const bottomFirstServe = {
    right: ['a1a', 'a2a', 'a3a', 'a1', 'a2', 'a3', 'a1b', 'a2b', 'a3b'],
    left: ['b3a', 'b2a', 'b1a', 'b3', 'b2', 'b1', 'b3b', 'b2b', 'b1b']
}
const SectionIn = {
    topFirstServe,
    topServe: [...sectionReturn(midBottom4Child2), ...sectionReturn(midBottom4), ...sectionReturn(midBottom4Child), ...sectionReturn(midBottom3), ...sectionReturn(midBottom2), ...sectionReturn(midBottom)],
    bottomFirstServe,
    bottomServe: [...sectionReturn(midTop4Child), ...sectionReturn(midTop4), ...sectionReturn(midTop4Child2), ...sectionReturn(midTop3), ...sectionReturn(midTop2), ...sectionReturn(midTop)]
}

const extraButtons = ['v', 's', 'd', 'l', 'r', 'f', 'o']

export {
    bgColor,
    drawerRoutes,
    googleClientId,
    tennisTop,
    tennisRight,
    tennisLeft,
    midTop,
    midTop2,
    midTop3,
    midTop4,
    midTop4Child,
    midTop4Child2,
    midBottom,
    midBottom2,
    midBottom3,
    midBottom4,
    midBottom4Child,
    midBottom4Child2,
    tennisCorners,
    oSection,
    facebookId,
    SectionIn,
    extraButtons
}