import React, { useEffect, useState } from 'react'
import allPaths from '../../Config/paths'
import { Menu, Button } from 'antd'
import { UserOutlined, UsergroupAddOutlined, FlagOutlined, MenuUnfoldOutlined, MenuFoldOutlined } from '@ant-design/icons'
import { Players, Tournaments, Users } from '.'
import { getAllData } from '../../utils/helpers'

const SideMenu = (props) => {
    const { user, history, authActions } = props
    const [collapsed, setCollapsed] = useState(window.innerWidth < 500 ? true : false)
    const [key, setKey] = useState(1)

    useEffect(() => {
        window.addEventListener('resize', setWindowWidth)
        setWindowWidth()

        if (!user?.isAdmin) {
            return history?.push(allPaths?.HOME)
        }
    }, [])

    useEffect(() => {
        getAllData(user?._id, authActions)
    }, [key])

    const setWindowWidth = () => {
        window.innerWidth < 500 ? setCollapsed(true) : setCollapsed(false)
    }

    const handleClick = (e) => {
        setKey(parseInt(e?.key))
    }

    const toggleCollapsed = () => {
        setCollapsed(!collapsed)
    }

    return (
        <div className='home-main'>
            <div className='flex-row'>
                <div>
                    <Button type='primary' onClick={toggleCollapsed} style={{ marginBottom: 6 }}>
                        {collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
                    </Button>
                    <Menu
                        onClick={handleClick}
                        style={{ height: `calc(100vh - 66px)` }}
                        defaultSelectedKeys={['1']}
                        defaultOpenKeys={['sub1']}
                        mode='inline'
                        inlineCollapsed={collapsed}
                    >
                        <Menu.Item key={1} icon={<UserOutlined className='icon-color' />}>
                            Users
                        </Menu.Item>
                        <Menu.Item key={2} icon={<UsergroupAddOutlined className='icon-color' />}>
                            Players
                        </Menu.Item>
                        <Menu.Item key={3} icon={<FlagOutlined className='icon-color' />}>
                            Tournaments
                        </Menu.Item>
                    </Menu>
                </div>
                <div style={{ flex: 1 }}>
                    {key === 1 ? <Users {...props} /> : key === 2 ? <Players {...props} /> : <Tournaments {...props} />}
                </div>
            </div>
        </div>
    )
}

export default SideMenu