import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { Form, Input, Button } from 'antd'
import { requiredMessage, inputPlace, successNotification, errorMessage } from '../../utils/helpers'
import { AUTH } from '../../utils/apis'
import { SocialLoginButtons } from '../../Components'
import axios from 'axios'
import { removeUser } from '../../Redux/actions/authActions'


const Signup = (props) => {
    const [loading, setLoading] = useState(false)
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(removeUser())
    }, [])

    const onFinish = (values) => {
        const { history } = props
        setLoading(true)
        axios.post(AUTH.SIGNUP, values)
            .then((res) => {
                const { data } = res
                setLoading(false)
                if (data.success) {
                    successNotification('Successfully Signup we have sent you a verification link to your email, Please verify your email and login!')
                    return setTimeout(() => {
                        history.push('/login')
                    }, 300)
                }
                errorMessage(data.message)
            })
            .catch((e) => {
                setLoading(false)
                errorMessage()
            })
    }

    return (
        <div className='card-parent'>
            <div className='card1'>
                <p className='heading head-center'>Login And Play Tennis</p>
                <Form
                    name='form'
                    onFinish={onFinish}
                    layout={'vertical'}
                    requiredMark={false}
                >
                    <Form.Item
                        name='fullName'
                        label='Full Name'
                        hasFeedback
                        rules={[
                            {
                                required: true,
                                message: requiredMessage('Full Name')
                            }
                        ]}
                    >
                        <Input
                            className='form-input'
                            placeholder={inputPlace('full name')}
                        />
                    </Form.Item>
                    <Form.Item
                        name='email'
                        label='Email'
                        hasFeedback
                        rules={[
                            {
                                required: true,
                                message: requiredMessage('Email')
                            },
                            {
                                type: 'email'
                            }
                        ]}
                    >
                        <Input
                            className='form-input'
                            placeholder={inputPlace('email')}
                        />
                    </Form.Item>
                    <Form.Item
                        name='password'
                        label='Password'
                        hasFeedback
                        rules={[
                            {
                                required: true,
                                message: requiredMessage('Password')
                            },
                            {
                                min: 6
                            }
                        ]}
                    >
                        <Input.Password
                            className='form-input'
                            placeholder={inputPlace('password')}
                        />
                    </Form.Item>
                    <Form.Item
                        label='Confirm Password'
                        name='confirm'
                        hasFeedback
                        rules={[
                            {
                                required: true,
                                message: requiredMessage('confirm password'),
                            },
                            ({ getFieldValue }) => ({
                                validator(rule, value) {
                                    if (!value || getFieldValue('password') === value) {
                                        return Promise.resolve()
                                    }

                                    return Promise.reject('The two passwords that you entered do not match!')
                                }
                            })
                        ]}
                    >
                        <Input.Password
                            placeholder={inputPlace('confirm password')}
                            className='form-input'
                        />
                    </Form.Item>
                    <Form.Item>
                        <Button
                            type='primary'
                            className='form-button'
                            block
                            htmlType='submit'
                            loading={loading}
                        >
                            Submit
                        </Button>
                    </Form.Item>
                </Form>
                <SocialLoginButtons />
                <p>Already have account? <Link to='/login'>Login</Link>
                    <br />
                    <Link to='/forgot'>Forgot Password</Link>
                </p>
            </div>
        </div>
    )
}

export default Signup